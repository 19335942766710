import React from 'react';
import Footer from './footer';
import Header from './header';
import gapimpimg from '../assets/img/gap-import.png';
import gapbenefitsimg from '../assets/img/gap-benefits-img.png';
import ScrollAnimation from 'react-animate-on-scroll';

const Gapanalysis = () => {
    return (
        <>
            <Header />
            <div className='main-sec-homes about-main gap-analys-head'>
                <div class="main-sec-home about-sec-banner gap-analys-bg" style={{ backgroundImage: `url(${require("../assets/img/gap-analisys-banner.jpg")})` }}>

                </div>
                <div className='container'>
                    <div className='banner-content abut-banner-cont'>
                        <h1>Gap Analysis</h1>

                        {/* <p className='head-para'>Delivering client-centric solutions for 12 years</p> */}

                    </div>
                </div>
            </div>

            <div className='servicess-inner-sec bg-sec-new'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='servicess-inner-para'>
                                <p>We collaborate closely with your institution to systematically evaluate and streamline your workflows.
                                    By identifying nuanced areas, we deliver tailored solutions that transform manual processes into efficient,
                                    automated systems, further enhancing accountability and optimization.</p>
                            </div>
                        </div>
                    </div>
                    <div className='servicess-divid'>
                        <div className='row'>
                            <div className='col-md-5'>
                                <ScrollAnimation animateIn="fadeIn">
                                    <div className='srvicess-left'>
                                        <img src={gapimpimg} />
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-md-7'>
                                <div className='srvicess-right'>
                                    <ScrollAnimation animateIn="fadeIn">
                                        <h2>Its Importance</h2>
                                        <p>Our service ensures that your institution not only keeps pace but excels by adopting smarter, faster,
                                            and more reliable processes. By addressing certain gaps in your current workflows,
                                            we help create smoother processes and improve the overall effectiveness of your operations to remain agile and future-ready.</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>

                        <div className='point-sec-service'>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className='srvicess-right'>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <h2>It’s Benefits</h2>
                                            <ul>
                                                <li>Enhanced accountability</li>
                                                <p> Implement systems that make every action traceable, ensuring that responsibilities are clear and errors are minimized.</p>
                                                <li>Scalability</li>
                                                <p> Create processes that not only solve today’s problems but also adapt to tomorrow’s challenges.</p>
                                                <li>Custom Solutions</li>
                                                <p>  Every solution is customized to meet the unique demands of your institution, ensuring that the changes we implement truly make a difference.</p>
                                            </ul>
                                        </ScrollAnimation>
                                    </div>

                                </div>
                                <div className='col-md-5'>


                                    <div className='srvicess-left point-srvicess-left'>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <img src={gapbenefitsimg} />
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Gapanalysis;
