import React, { useState, useRef, useEffect } from "react";
import privacyimg from '../assets/img/services-banner.jpg';
import Header from './header';
import Footer from './footer';
import {
    Link,
} from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
const Privacypolicy = () => {
    const { privacypolicyData } = useSelector((state) => state.pageContents);

    const [currentPage, setCurrentPage] = useState(1);

    const handleClickOpenback = (val) => {
        if (currentPage == 1 && val == 'prev') {
            setCurrentPage(2);
        } else if (currentPage == 2 && val == 'prev') {
            setCurrentPage(3);
        } else if (currentPage == 2 && val == 'back') {
            setCurrentPage(1);
        } else if (currentPage == 3 && val == 'back') {
            setCurrentPage(2);
        }
        window.scrollTo(0, 0);

    };
    return (
        <>
            <Header />


            {
                privacypolicyData?.privacybanner &&
                <div className='main-sec-homes about-main'>
                    <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${privacypolicyData.privacybanner.banner_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont'>

                            <h1>{privacypolicyData.privacybanner.banner_title}</h1>

                            <p className='head-para'>{privacypolicyData.privacybanner.banner_title_short}</p>

                        </div>
                    </div>
                </div>
            }
            {
                privacypolicyData?.privacybanner &&
                <div className='main-sec-homes about-main  mob-sec-new'>
                    <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${privacypolicyData.privacybanner.banner_mobile_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont'>
                            <h1>{privacypolicyData.privacybanner.banner_title}</h1>


                            <p className='head-para'>{privacypolicyData.privacybanner.banner_title_short}</p>

                        </div>
                    </div>
                </div>
            }
            {/* privacy policy section start */}
            <div className='privacy-main-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='privacy-inner'>
                                {
                                    privacypolicyData?.privacypage &&
                                    <>
                                        {currentPage == 1
                                            &&
                                            <>
                                                {privacypolicyData.privacypage.page_title != null && privacypolicyData.privacypage.page_title != '' &&
                                                    <div dangerouslySetInnerHTML={{ __html: privacypolicyData.privacypage.page_title }}></div>

                                                }
                                            </>
                                        }

                                        {currentPage == 2
                                            &&
                                            <>
                                                {privacypolicyData.privacypage.page_short_des != null && privacypolicyData.privacypage.page_short_des != '' &&
                                                    <div dangerouslySetInnerHTML={{ __html: privacypolicyData.privacypage.page_short_des }}></div>

                                                }
                                            </>
                                        }
                                        {currentPage == 3
                                            &&
                                            <>
                                                {privacypolicyData.privacypage.page_des != null && privacypolicyData.privacypage.page_des != '' &&
                                                    <div dangerouslySetInnerHTML={{ __html: privacypolicyData.privacypage.page_des }}></div>

                                                }
                                            </>
                                        }

                                    </>

                                }
                                <div className="btn-prebck">
                                {currentPage != 1 &&

                                    <button
                                        onClick={() => handleClickOpenback('back')} className="prev-sec-btn"

                                    >
<i class="fa-solid fa-angle-left"></i>Prev
                                    </button>
                                }
                                {currentPage != 3 &&

                                    <button
                                        onClick={() => handleClickOpenback('prev')} className="prev-sec-btn next-sec-btn"

                                    >
                                        Next<i class="fa-solid fa-angle-right"></i>
                                    </button>
                                }
</div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* privacy policy section end  */}
            <Footer />
        </>
    )
}

export default Privacypolicy;