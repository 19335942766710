import React, { useState, useRef, useEffect } from "react";
import Header from './header';
import Footer from './footer';
import {
  Link,
} from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
const Termscondition = () => {
  const { cancelpolicyData } = useSelector((state) => state.pageContents);

  return (
    <>
      <Header />
      {
        cancelpolicyData?.termsbanner &&
        <div className='main-sec-homes about-main'>
          <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${cancelpolicyData.termsbanner.banner_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>

              <h1>{cancelpolicyData.termsbanner.banner_title}</h1>

              <p className='head-para'>{cancelpolicyData.termsbanner.banner_title_short}</p>

            </div>
          </div>
        </div>
      }
      {
        cancelpolicyData?.termsbanner &&
        <div className='main-sec-homes about-main  mob-sec-new'>
          <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${cancelpolicyData.termsbanner.banner_mobile_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>
              <h1>{cancelpolicyData.termsbanner.banner_title}</h1>


              <p className='head-para'>{cancelpolicyData.termsbanner.banner_title_short}</p>

            </div>
          </div>
        </div>
      }
      {/* terms and consitions section start */}
      <div className='privacy-main-sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='privacy-inner'>

                {
                  cancelpolicyData?.termspage &&
                  <>

                    {cancelpolicyData.termspage.page_title != null && cancelpolicyData.termspage.page_title != '' &&
                      <div dangerouslySetInnerHTML={{ __html: cancelpolicyData.termspage.page_title }}></div>

                    }




                  </>

                }

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* terms and condition section end */}
      <Footer />
    </>
  )
}
export default Termscondition;
