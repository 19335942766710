import React, { useState, useRef, useEffect } from "react";
import Header from './header';
import Footer from './footer';
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ReCAPTCHA from "react-google-recaptcha";
import {
    Link
  } from "react-router-dom";

const Bookademo = () => {
    const { projectData } = useSelector((state) => state.pageContents);
    
    const handleDate = (e) => {
        let temp = { ...formValues }
    
        var d = new Date(e);
        var date;
        var month;
        if (d.getDate() < 10) {
          date = "0" + d.getDate();
        }
        else {
          date = d.getDate();
        }
    
        if ((d.getMonth() + 1) < 10) {
          month = "0" + (d.getMonth() + 1);
        }
        else {
          month = (d.getMonth() + 1);
        }
        var datestring = d.getFullYear() + "-" + month + "-" + date;
        temp.sh_date = datestring;
        temp.sh_date_new = e;
    
        console.log(datestring);
        setFormErrors({ ...formErrors, ['sh_date']: null });
        setFormValues(temp);
      }
    
    
    
      const handleTime = (e) => {
        let temp = { ...formValues }
        console.log(e);
        var d = new Date(e);
        var hour;
        var hours;
        var mintues;
        hours=d.getHours();
        hour = d.getHours();
        mintues = d.getMinutes();
        console.log(hour);
       
        hour = hour % 12;
        hour = hour ? hour : 12;
        temp.sh_time = addZero(hour) + ':' + addZero(mintues) + ' ' + (hours >= 12 ? 'PM' : 'AM');
        temp.sh_time_new = e;
    
         console.log(temp.sh_time);
        setFormErrors({ ...formErrors, ['sh_time']: null });
        setFormValues(temp);
      }
      const formFields = {
        name: '',
        email: '',
        phone: '',
        contact_us: '',
        casestudyname: '',
        pagename: 'morecasestudies',
    
        sh_date: '',
        sh_time: '',
    
        sh_date_new: null,
        sh_time_new: null
    
      }
      const [formValues, setFormValues] = React.useState(formFields);
      const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
      const [formdateto, setFormdateto] = useState(null);
      
      function addZero(i) {
        if (i < 10) {i = "0" + i}
        return i;
      }

      const [age, setAge] = React.useState('');

      const handleChange = (event) => {
        console.log(event.target.value);
        setAge(event.target.value);
      };
      const validateForm = () => {
        let isValid = true;
        let errors = {};
        Object.keys(formValues).forEach((key) => {
          if (!formValues[key] && key !== 'recaptcha' && key !== 'phone' && key !== 'sh_date_new' && key!='sh_time_new' && key !== 'contact_us' && key !== 'casestudyname'
    
    
          ) {
            isValid = false;
            errors[key] = 'This field is required';
          } else if (key === 'email' && !isEmail(formValues[key])) {
            isValid = false;
            errors[key] = 'Invalid email address';
          }
          // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
          //   isValid = false;
          //   errors[key] = 'Invalid phone number';
          // }
    
        });
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
          isValid = false;
          errors.recaptcha = 'Please verify captcha';
        }
        console.log(errors);
        setFormErrors(errors);
        return isValid;
      }
      const recaptchaRef = React.useRef();
  return (
    
    <>
    <Header />
    {
                projectData?.projectbanner &&
                <div className='main-sec-homes about-main'>
                    <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${projectData.projectbanner.banner_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont'>
                            <h1>{projectData.projectbanner.banner_title}</h1>
                            <p className='head-para'>{projectData.projectbanner.banner_title_short}</p>

                        </div>
                    </div>
                </div>
            }

            {
                projectData?.projectbanner &&
                <div className='main-sec-homes about-main mob-sec-new'>
                    <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${projectData.projectbanner.banner_mobile_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont'>
                            <h1>{projectData.projectbanner.banner_title}</h1>

                            <p className='head-para'>{projectData.projectbanner.banner_title_short}</p>
                        </div>
                    </div>
                </div>
            }
            <div className='book-sec-project bg-book'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="bok-iner-head">
                            <h2>You are requesting for a demo</h2> 
                            </div>
                            <div className='book-inner-projt final-modal date-modal'>
                           
                             
                                <div className="form-sec-main">
                                    <div className="form-select-sec">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                            <InputLabel id="demo-simple-select-standard-label">Choose Project</InputLabel>
                                            <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={age}
                                            onChange={handleChange}
                                            label="Choose Project"
                                            >
                                           
                                            <MenuItem value={10}>E-form Approval and Routing System</MenuItem>
                                            <MenuItem value={20}>Workshop Management System</MenuItem>
                                            <MenuItem value={30}>Student Transcript Application</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                             
                                  
                                        <div className='form-sec form-modal date-modal-case data-new-time'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={[
                                'DatePicker',
                                'MobileDatePicker',
                                'DesktopDatePicker',
                                'StaticDatePicker',
                                ]}
                            >


                                <MobileDatePicker
                                label="Select Date"
                                variant="standard"
                                inputFormat="MM/dd/yyyy"
                                placeholder="MM/dd/yyyy"
                                value={formValues.sh_date_new}

                                onChange={handleDate}
                                minDate={dayjs(formdateto)}
                                renderInput={(params) => <TextField {...params} />}
                                />

                            </DemoContainer>
                            </LocalizationProvider>

                            {
                            formErrors.sh_date &&
                            <div className="invalid-feedbackcontact">
                                <div className='error-msg'>
                                {formErrors.sh_date}
                                </div>
                            </div>
                            }

                                        </div>
                                 
                                  
                                   
                                
                                <div className='form-sec form-modal'>
                                    <p className="case-privacy info-privacy-sec">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
                                </div>

                                <div className='form-sec form-modal' >
                                    <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                                    />
                                    {
                                    formErrors.recaptcha &&
                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                        <div className='error-msg'>
                                        {formErrors.recaptcha}
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div class="button-sec button-case cse-new"><a class="btn btn-sec btn-yes btn-submit">Submit</a></div>

                                </div>  
                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    <Footer />
    </>
  )
}
export default Bookademo;
