import React, { useState, useRef, useEffect } from "react";
import Header from './header';
import Footer from './footer';
import playicon from '../assets/img/play.png';
import objimg from '../assets/img/objective_img.png';
import resolimg from '../assets/img/resol-_img.png';
import outcomeimg from '../assets/img/outcome_img.png';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {  InputAdornment } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams,
  useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Media, Video, PlayButton } from '@vidstack/player-react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Player } from "video-react";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

// modal code start here

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
  addConnect,
  otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

// modal code end here
const CustomPlayIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 24 24"
    fill="yellow"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2.5v19l16-9.5-16-9.5z"
    />
  </svg>
);

// modal code start here
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function addZero(i) {
  if (i < 10) {i = "0" + i}
  return i;
}
// modal code end here
export const Casestudies = () => {
  let navigate = useNavigate();
  const [pageselect, setPageselect] = React.useState('');
  const { casestudiesData } = useSelector((state) => state.pageContents);
  const { slug } = useParams();
  console.log(casestudiesData);
  console.log(slug);
  const [isPaused, setIsPaused] = useState(true);
  const [strcase, setStrcase] = useState('');
  const [strcasenew, setStrcasenew] = useState('');
  const [casestudy, setCasestudy] = useState('');
  const videoRef = useRef(null);

  const [formdateto, setFormdateto] = useState(null);
  const recaptchaRef = React.useRef();


  const handlePlayPause = () => {

    if (isPaused == true) {

      setIsPaused(false);
    } else {

      setIsPaused(true);
    }
  };
  const location = useLocation();
  useEffect(() => {

    const hash = window.location.hash;
    const sectionId = hash.substring(1);
    console.log(sectionId);
    if (sectionId) {
      //alert(sectionId);
      const element = document.getElementById(sectionId);
      if (element) {
        scrollToSection(element);
      }
    }
  }, [location.pathname]);
  useEffect(() => {
    console.log('hi');;

    var date = new Date();
    setFormdateto(date);

  }, []);
  const scrollToSection = (elem) => {
    window.scrollTo({
      top: elem.offsetTop - 50,
      behavior: "smooth",
    });
  };
  const handleclickmenu = (e) => {
    // alert(strcase);
    setCasestudy('');
    if (casestudiesData?.casestudies.length > 0) {
      let count = 0;
      let countf = 0;
      var tgitle = '';
      let str = casestudiesData.casestudies.map(function (el) {
        var o = Object.assign({}, el);
        if (o.slug == slug) {
          tgitle = o.title;
          count = o.id + 1;

          if (count == 3) {
            count = 4;
          }

        }

        if (count == o.id) {
          setStrcasenew('casestudies');
          navigate(`/casestudies/${o.slug}`);

          // if (o.id % 2 === 0) {
          //   if (o.id != 3 && o.id < 5) {
          //     navigate(`/casestudies/${o.slug}`);
          //   } else {
          //     if(o.id==5){
          //       navigate(`/casestudies/${o.slug}`);
          //     }else{
          //       var casedeat = { ...formValues };
          //       casedeat.casestudyname = tgitle;
          //       casedeat.name = '';
          //       casedeat.email = '';
          //       casedeat.phone = '';
          //       casedeat.contact_us = '';


          //       console.log(casedeat);
          //       setFormValues(casedeat);
          //       setCasestudy(tgitle);
          //       setFormErrors(updateNullOfObjectValues(formFields));

          //       handleClickOpenCasestudies();
          //     }


          //   }

          // } else {
          //   if(o.id==5){
          //     navigate(`/casestudies/${o.slug}`);
          //   }else{
          //     var casedeat = { ...formValues };
          //     casedeat.casestudyname = tgitle;
          //     casedeat.name = '';
          //     casedeat.email = '';
          //     casedeat.phone = '';
          //     casedeat.contact_us = '';


          //     setFormValues(casedeat);
          //     setCasestudy(tgitle);
          //     setFormErrors(updateNullOfObjectValues(formFields));

          //     handleClickOpenCasestudies();
          //   }


          // }

        }
        countf++;
        //return o;
      });
    }
  };
  const handleclickmenuback = (e, backid) => {

    if (casestudiesData?.casestudies.length > 0) {
      let count = 0;
      let countf = 0;
      var tgitle = '';
      var frslug = '';
      var bcknew = backid - 1;
      if (bcknew == 3) {
        bcknew = 2;
      }
      let str = casestudiesData.casestudies.map(function (el) {
        var o = Object.assign({}, el);
        if (o.id == 5) {
          frslug = o.slug;
        }
        if (backid == slug) {
          tgitle = o.title;
          count = o.id - 1;

          if (count == 0) {
            count = 5;
          }

        }
        console.log(count);
        if (bcknew == o.id) {

          navigate(`/casestudies/${o.slug}`);



        } else {


        }
        countf++;
        //return o;
      });
    }
  };
  // Modal code start here
  const [opencase, setOpenCase] = React.useState(false);

  const handleClickOpenCasestudies = () => {
    setOpenCase(true);
  };
  const handleClosecase = () => {
    setOpenCase(false);
  };



  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const [opencaseno, setOpenCaseno] = React.useState(false);

  const handleClickOpenCaseno = () => {
    setOpenCaseno(true);
    setOpenCase(false);
  };
  const handleClosecaseno = () => {
    setOpenCaseyes(false);
    setOpenCaseno(false);
  };




  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    pagename: 'morecasestudies',

    sh_date: '',
    sh_time: '01:30 PM',

    sh_date_new: null,
    sh_time_new: null

  }
  const [opendate, setOpenDate] = useState(false); // State to control the open/close
  const handleIconClick = () => {
     setOpenDate(true); // Open the picker when the icon is clicked
   };
 
  const handleDate = (e) => {
    let temp = { ...formValues }

    var d = new Date(e);
    var date;
    var month;
    if (d.getDate() < 10) {
      date = "0" + d.getDate();
    }
    else {
      date = d.getDate();
    }

    if ((d.getMonth() + 1) < 10) {
      month = "0" + (d.getMonth() + 1);
    }
    else {
      month = (d.getMonth() + 1);
    }
    var datestring = d.getFullYear() + "-" + month + "-" + date;
    temp.sh_date = datestring;
    temp.sh_date_new = e;

    console.log(datestring);
    setFormErrors({ ...formErrors, ['sh_date']: null });
    setFormValues(temp);
    setOpenDate(false);
  }



  const handleTime = (e) => {
    let temp = { ...formValues }
    console.log(e);
    var d = new Date(e);
    var hour;
    var hours;
    var mintues;
    hours=d.getHours();
    hour = d.getHours();
    mintues = d.getMinutes();
    console.log(hour);
   
    hour = hour % 12;
    hour = hour ? hour : 12;
    temp.sh_time = addZero(hour) + ':' + addZero(mintues) + ' ' + (hours >= 12 ? 'PM' : 'AM');
    temp.sh_time_new = e;

     console.log(temp.sh_time);
    setFormErrors({ ...formErrors, ['sh_time']: null });
    setFormValues(temp);
  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);

  const [openotp, setOpenotp] = React.useState(false);

  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }
  const handleClickOpenCaseyes = (title) => {
    var casedeat = { ...formValues };
    casedeat.casestudyname = title;
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.pagename = 'morecasestudies';
    casedeat.sh_date = '2024-02-08';
    casedeat.sh_time = '1:30 PM';
    casedeat.sh_date_new = null;
    casedeat.sh_time_new = null;
    setFormValues(casedeat);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOtpin({
      otpIn_first: "",
      otpIn_secon: "",
      otpIn_thir: "",
      otpIn_forth: "",
      otpIn_fifth: "",
      otpIn_six: "",
      error_first: 1,
      error_secon: 1,
      error_thir: 1,
      error_forth: 1,
      error_fifth: 1,
      error_six: 1,
      signIn_err: '0',
      signIn_mesage: '',
      status: 'fail',
      id: ''
    });
    setOpenCaseyes(true);

  };
  const handleClickOpenGetintouch = (title) => {


    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);


    var casedeat = { ...formValues };
    casedeat.casestudyname = title;
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.pagename = 'bookdemo';
    casedeat.sh_date = '';
    casedeat.sh_time = '01:30 PM';
    casedeat.sh_date_new = null;
    casedeat.sh_time_new = null;
    setFormValues(casedeat);
    setOtpin({
      otpIn_first: "",
      otpIn_secon: "",
      otpIn_thir: "",
      otpIn_forth: "",
      otpIn_fifth: "",
      otpIn_six: "",
      error_first: 1,
      error_secon: 1,
      error_thir: 1,
      error_forth: 1,
      error_fifth: 1,
      error_six: 1,
      signIn_err: '0',
      signIn_mesage: '',
      status: 'fail',
      id: ''
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'phone' && key !== 'sh_date_new' && key!='sh_time_new' && key !== 'contact_us' && key !== 'casestudyname'


      ) {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
      //   isValid = false;
      //   errors[key] = 'Invalid phone number';
      // }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }

  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
        handleSubmitotp();
    }
 
 }, [otpIn]);
  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
          // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          if(pageselect=='morecasestudies'){
            navigate('/thankyou/morecasestudies');
          }else if(pageselect=='bookdemo'){
            navigate('/thankyou/bookademocasestudies');
          }
          // setTimeout(() => {
          //   setOpen(false);
          // }, 3000);






        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }

  // Modal code end here
  return (
    <>
      <Header />
      {
        casestudiesData?.casestudiesbanner &&
        <div className='main-sec-homes about-main'>
          <div class="main-sec-home about-sec-banner" title="Case Studies Banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${casestudiesData.casestudiesbanner.banner_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>
              <h1>{casestudiesData.casestudiesbanner.banner_title}</h1>

              <p className='head-para'>{casestudiesData.casestudiesbanner.banner_title_short}</p>

            </div>
          </div>
        </div>
      }

      {
        casestudiesData?.casestudiesbanner &&
        <div className='main-sec-homes about-main  mob-sec-new'>
          <div class="main-sec-home about-sec-banner"  title="Case Studies Banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${casestudiesData.casestudiesbanner.banner_mobile_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>
              <h1>{casestudiesData.casestudiesbanner.banner_title}</h1>

              <p className='head-para'>{casestudiesData.casestudiesbanner.banner_title_short}</p>

            </div>
          </div>
        </div>
      }

      {/* banner below section start */}
      <div className='bg-sec-newss'>
        {
          casestudiesData?.casestudiespage &&
          <>
            <div className='case-banner-below'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='case-banner-below-inner'>

                      <h3>{casestudiesData.casestudiespage.page_title} </h3>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            {/* banner below section end */}

            {/* intervention section start */}
            <div className='interven-sec'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className="new-image">

                      <div className="new-imagess">
                        <h3>{casestudiesData.casestudiespage.page_short_des} </h3>
                        <img src={process.env.REACT_APP_IMAGE_URL + casestudiesData.casestudiespage.image}
                         alt={'New Jersey Department of Education logo'} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* intervention section end */}
          </>
        }
        {
          casestudiesData?.casestudies.length &&
          <>
            {
              casestudiesData.casestudies.map((pro, index) => (
                <>
                  {pro.slug == slug &&
                    <>
                      {/* project case study section section start*/}
                      <div className='case-stud-sec' >
                        <div className='container'>
                          <div className='row'>
                            <div className='col-md-12 case-new-mar'>
                              <div className='case-stud-sec-inner case-new-insd'>
                                {/* <ScrollAnimation animateIn="fadeIn"> */}
                                <h3>{pro.title}</h3>
                                {/* </ScrollAnimation> */}
                              </div>
                              {/* <ScrollAnimation animateIn="fadeIn"> */}
                                <div className="book-demo-sec">
                                  <a className="btn btn-sec btn-book-sec" href="javascript:void(0)" onClick={() => handleClickOpenGetintouch(pro.title)}>Book a Free Demo</a>
                                </div>
                              {/* </ScrollAnimation> */}

                            </div>
                          </div>

                          {/* <div className="row">
                  <div className="col-md-12">
                    <div className="book-demo-sec">    
                        <a className="btn btn-sec btn-book-sec" >Book a Demo</a>
                    </div>
                  </div>
                </div> */}

                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='case-stud-data-sec'>
                                <div className='case-stud-icon-sec'>
                                  {/* <ScrollAnimation animateIn="fadeIn"> */}
                                    <div className='case-img'>
                                      <img src={objimg} alt={pro.first_title}/>
                                    </div>
                                    <div className='case-heading'>
                                      <h4>{pro.first_title}</h4>
                                    </div>
                                  {/* </ScrollAnimation> */}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className='case-stud-data'>
                                {/* <ScrollAnimation animateIn="fadeIn"> */}


                                  <p>{pro.first_des}</p>
                                {/* </ScrollAnimation> */}
                              </div>
                            </div>
                          </div>


                          <div className='row margn-case'>
                            <div className='col-md-12'>
                              <div className='case-stud-data-sec'>
                                <div className='case-stud-icon-sec'>
                                  {/* <ScrollAnimation animateIn="fadeIn"> */}
                                    <div className='case-img'>
                                      <img src={resolimg} alt={pro.second_title} />
                                    </div>
                                    <div className='case-heading'>
                                      <h4>{pro.second_title}</h4>
                                    </div>
                                  {/* </ScrollAnimation> */}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className='case-stud-data'>
                                {/* <ScrollAnimation animateIn="fadeIn"> */}
                                  {pro.second_des != null && pro.second_des != '' &&
                                    <div dangerouslySetInnerHTML={{ __html: pro.second_des }}></div>

                                  }

                                {/* </ScrollAnimation> */}
                              </div>
                            </div>
                          </div>


                          <div className='row margn-case'>
                            <div className='col-md-12'>
                              <div className='case-stud-data-sec'>
                                <div className='case-stud-icon-sec'>
                                  {/* <ScrollAnimation animateIn="fadeIn"> */}
                                    <div className='case-img'>
                                      <img src={outcomeimg}   alt={pro.third_title} />
                                    </div>
                                    <div className='case-heading'>
                                      <h4>{pro.third_title}</h4>
                                    </div>
                                  {/* </ScrollAnimation> */}
                                </div>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className='case-stud-data'>
                                {/* <ScrollAnimation animateIn="fadeIn"> */}
                                  {pro.third_des != null && pro.third_des != '' &&
                                    <div dangerouslySetInnerHTML={{ __html: pro.third_des }}></div>

                                  }

                                {/* </ScrollAnimation> */}
                              </div>
                            </div>
                          </div>








                        </div>
                      </div>
                    </>
                  }

                </>
              ))
            }
          </>

        }

      </div>
      {/* project case study section section end*/}
      {/* watch video section start */}

      {
        casestudiesData?.casestudies.length &&
        <>
          {
            casestudiesData.casestudies.map((pro, index) => (
              <>
                {(pro.slug == slug) &&
                  <>
                    <div className={`watch-video-sec bg-sec-new-video ${(pro.video == null && pro.video_image == null) && 'no-videos'}`}>
                      <div className='container'>


                        {(pro.video != null && pro.video_image != null && pro.video != '' && pro.video_image != '') &&

                          <>
                            <div className='row'>
                              <ScrollAnimation animateIn="fadeIn">
                                <div className='col-md-12'>
                                  <div className='watcch-inner-sec'>
                                    <div className='watch-sec desk-watch'>
                                      <p>Watch<br></br> Product Video</p>
                                    </div>

                                    <div className='watch-sec mobile-watch'>
                                      <p>Watch Product Video</p>
                                    </div>

                                    <div className='watch-video-play'>
                                      <div className='watch-vid-play-inner'>

                                        <Media className={`media-sec`} style={{ width: '700px', height: '399px' }}>

                                          <Video loading="visible" controls preload="true" poster={process.env.REACT_APP_IMAGE_URL + pro.video_image}    >

                                            <video className={`video-cse ${isPaused == false && 'cloverpa'}`} loading="visible" poster={process.env.REACT_APP_IMAGE_URL + pro.video_image} src={process.env.REACT_APP_IMAGE_URL + pro.video} preload="none" data-video="0" controls />
                                            {isPaused && (
                                              <PlayButton className="custom-play-button" onClick={handlePlayPause}>

                                                <div className="video-main-secs">
                                                  <CustomPlayIcon className="video-react-button" />
                                                </div>

                                              </PlayButton>
                                            )}





                                          </Video>
                                        </Media>


                                      </div>
                                    </div>
                                  </div>


                                </div>

                              </ScrollAnimation>

                            </div>

                          </>
                        }


                        <div className="row">
                          <div className="col-md-12">
                            <ScrollAnimation animateIn="fadeIn">

                              <div class={`btn-prebck btn-ser-explre case-main-arow-sec ${(pro.video != null && pro.video_image != null && pro.video != '' && pro.video_image != '') && 'with-video-case'}`} >
                                <div className='button-sec servic-btn-sec leader-sec ser-page-sec'>
                                  {pro.id != 1 &&

                                    <a className={`prev-sec-Case Studies case-pre-new ${ pro.id < 5 && 'line-after-case'}`} onClick={(e) => handleclickmenuback(e, (pro.id))} >
                                      <i class="fa-solid fa-arrow-left-long arrow-one left-arrow"></i><span>Go Back to Previous Case Study</span></a>

                                  }

                                  {pro.id < 5 &&
                                    <a className="next-sec-Case Studies case-back-new" onClick={(e) => handleclickmenu()}><span>View More Case Studies</span><i class="fa-solid fa-arrow-right-long arrow-one"></i></a>
                                  }
  {pro.id >= 5 &&
                         
                              // <ScrollAnimation animateIn="fadeIn">
                                <a className={`button-sec case-btn-sec more-sec-main ${(pro.video == null && pro.video_image == null) && 'no-vide--btn'}`}>
                                  <a className='btn btn-sec more-btn' onClick={() => handleClickOpenCaseyes(pro.title)} >ASK FOR MORE CASE STUDIES</a>
                                </a>

                              // </ScrollAnimation>


                           
                        }


                                </div>

                              </div>
                            </ScrollAnimation>
                          </div>
                        </div>


                        {/* {pro.id >= 5 &&
                          <div className='row'>
                            <div className='col-md-12'>
                              <ScrollAnimation animateIn="fadeIn">
                                <div className={`button-sec case-btn-sec ${(pro.video == null && pro.video_image == null) && 'no-vide--btn'}`}>
                                  <a className='btn btn-sec' onClick={() => handleClickOpenCaseyes(pro.title)} >ASK FOR MORE CASE STUDIES</a>
                                </div>

                              </ScrollAnimation>


                            </div>
                          </div>
                        } */}

                      </div>

                    </div>
                  </>
                }

              </>
            ))
          }

        </>

      }



      {/* watch video section end */}
      {/* modal code start here */}

      {/* modal code end here   */}

      {/* modal for yes */}


      {/* modal for yes end */}

      {/* modal for no start */}

      



      <BootstrapDialog
        onClose={handleClosecaseyes}
        aria-labelledby="customized-dialog-title"
        open={opencaseyes} className={`modal-casestudy modal-yes happy-modal modal-cases final-modal date-modal ${formValues.pagename == 'morecasestudies' && 'final-no-modal'}`}
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClosecaseyes}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Happy to help!</h2>
            {formValues.pagename == 'bookdemo' &&
            <>
              <p className="new-demo-head">You are requesting a demo for </p>
              <p className="new-demo-para">"{formValues.casestudyname}"</p>
            </>
          
            }
            
            {/* <p>Let us send you more case studies</p> */}

            <div className="form-sec-main">
              <div className='form-sec form-modal form-modals'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Full Name" variant="standard"
                    name="name"
                    className={classNames("form-control", { 'is-invalid': formErrors.name })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.name}

                  />
                  {
                    formErrors.name &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.name}
                      </div>
                    </div>
                  }
                </Box>
              </div>
              <div className='form-sec form-modal form-modals'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Email Id" variant="standard"
                    name="email"
                    className={classNames("form-control", { 'is-invalid': formErrors.email })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.email}

                  />
                  {
                    formErrors.email &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.email}
                      </div>
                    </div>
                  }
                </Box>
                <p className='veri-secss'><span>This email will be verified</span></p>

              </div>
              <div className='form-sec form-modal form-modals'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Contact Number" variant="standard"

                    name="phone"
                    className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.phone}
                  />
                  {
                    formErrors.phone &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.phone}
                      </div>
                    </div>
                  }
                </Box>

                <div class="tooltipnew"> <i class="fa-solid fa-info-circle"></i>
                  <span class="tooltiptext">In case we need to contact you</span>
                </div>

              </div>

              {formValues.pagename == 'bookdemo' &&
                <>
                  <div className='form-sec form-modal date-modal-case data-scroll-modal'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DatePicker',
                          'MobileDatePicker',
                          'DesktopDatePicker',
                          'StaticDatePicker',
                        ]}
                      >


                        <MobileDatePicker
                          label="Select Date"
                          variant="standard"
                          inputFormat="MM/dd/yyyy"
                          placeholder="MM/dd/yyyy"
                          value={formValues.sh_date_new}
                          open={opendate} // Control the open state of the picker
                          onClose={() => setOpenDate(false)} 
                          onOpen={() => setOpenDate(true)} // Close the picker when it opens via text field
                         
                          onChange={handleDate}
                          minDate={dayjs(formdateto)}
                          renderInput={(params) => <TextField {...params} 
                          
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleIconClick}>
                                  <CalendarMonthIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                         
                          />}
                        />
                        <span className="calend-icon" onClick={handleIconClick} >
                        <CalendarMonthIcon /></span>
                      </DemoContainer>
                    </LocalizationProvider>

                    {
                      formErrors.sh_date &&
                      <div className="invalid-feedbackcontact">
                        <div className='error-msg'>
                          {formErrors.sh_date}
                        </div>
                      </div>
                    }

                  </div>
                  {/* <div className='form-sec form-modal date-modal-case data-modal-mob'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'MobileTimePicker',
                          'DatePicker',
                          'MobileDatePicker',
                          'DesktopDatePicker',
                          'StaticDatePicker',
                        ]}
                      >


                        <MobileTimePicker
                          label="Select Time"
                          variant="standard"
                          inputFormat="hh:mm aa"
                          placeholder="hh:mm aa"
                          value={formValues.sh_time_new}

                          onChange={handleTime} className="time-sec"

                        />

                      </DemoContainer>
                    </LocalizationProvider>

                    {
                      formErrors.sh_time &&
                      <div className="invalid-feedbackcontact">
                        <div className='error-msg'>
                          {formErrors.sh_time}
                        </div>
                      </div>
                    }

                  </div> */}
                </>

              }


              <div className='form-sec form-modal'>

                <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
              </div>
              <div className='form-sec form-modal' >


                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                />
                {
                  formErrors.recaptcha &&
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    <div className='error-msg'>
                      {formErrors.recaptcha}
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className='button-sec button-case'>

              <a className='btn btn-sec btn-yes' onClick={handleSubmit}>Submit

                {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}
              </a>
            </div>
            <div className='validate-sec validate-sec-mode'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </Typography>

        </DialogContent>

      </BootstrapDialog>


   
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="modal-casestudy contact-thanku modal-thanku-connet"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Thank You !!</h2>
            <p>Your message is successfully delivered to the concerned team, they will do the needful soon</p>


          </Typography>

        </DialogContent>

      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="modal-casestudy modal-yes happy-modal otp-modal-new"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleCloseotp}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers className="new-otp">
          <Typography gutterBottom >
            <p className="otp-head">Please check your spam or junk folder, if your authentication code is not visible in your inbox
            </p>

            <div className="form-sec-main otp-main-secss">

              <div className='form-sec form-modal'>
                <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
                </div>



              </div>
              <div className='button-sec button-case btn-otp'>

                <a className='btn btn-sec btn-yes btn-sub' onClick={handleSubmitotp}>Submit

                  {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>}
                </a>
              </div>
              <div className='validate-sec validate-sec-mode width-otp'>
                {
                  successMessage &&
                  <div className="success-msg-sec">
                    <p className="succ-msg">{successMessage}
                    </p>
                  </div>
                }
                {
                  errorMessage &&
                  <div className="error-msg-sec">
                    <p className="error-msg">{errorMessage}
                    </p>
                  </div>
                }
              </div>
            </div>
          </Typography>

        </DialogContent>

      </BootstrapDialog>
      {/* modal for no end */}

      <Footer />
    </>
  )
}
export default Casestudies;
