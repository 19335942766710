import React, { useState } from "react";
import Header from './header';
import Footer from './footer';
import expertimg from '../assets/img/our-expert_img.png';
import { Link,Navigate,
  useNavigate } from 'react-router-dom';

import { useSelector } from "react-redux";
import classNames from "classnames";
import ScrollAnimation from 'react-animate-on-scroll';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
  addConnect,
  otpUpdate
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import  secureLocalStorage  from  "react-secure-storage";
// modal code start here
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// modal code end here

const Aboutus = () => {
  const { aboutData } = useSelector((state) => state.pageContents);
  const recaptchaRef = React.useRef();
  let navigate = useNavigate();
  const [pageselect, setPageselect] = React.useState('');
  React.useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
    console.log('generateUniqueToken', generateUniqueToken());
  }, []);
  const [opencaseyes, setOpenCaseyes] = React.useState(false);


  const handleClosecaseyes = () => {
    setOpenCaseyes(false);
  };



  const formFields = {
    name: '',
    email: '',
    phone: '',
    contact_us: '',
    casestudyname: '',
    pagename: 'leader',

    sh_date: '',
    sh_time: '',


  }
  const [loader, setLoader] = React.useState(true);

  const { ip_address } = useSelector((state) => state.userdata);
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [otpid, setotpid] = React.useState(null);

  const [openotp, setOpenotp] = React.useState(false);

  const handleClickOpenotp = () => {
    setOpenotp(true);
  };
  const handleCloseotp = () => {
    setOpenotp(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(formValues);
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  }
  const handleClickOpenCaseyes = () => {
    setFormValues(formFields);
    //    recaptchaRef.current.reset();
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);

  };
  const handleClickOpenGetintouch = (e) => {

    setFormValues(formFields);
    setFormErrors(updateNullOfObjectValues(formFields));
    setErrorMessage('');
    setSuccessMessage('');
    setOpenCaseyes(true);


    var casedeat = { ...formValues };
    casedeat.casestudyname = '';
    casedeat.name = '';
    casedeat.email = '';
    casedeat.phone = '';
    casedeat.contact_us = '';
    casedeat.pagename = 'leader';
    casedeat.sh_date = '';
    casedeat.sh_time = '';
    setFormValues(casedeat);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();
      setPageselect(formData.pagename);
      secureLocalStorage.setItem('selectoption', formData);
      addConnect(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {
            setotpid(response.data.lastid);
            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();
            setOpenCaseyes(false);
            setOpenotp(true);
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    //setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'phone' && key !== 'sh_date' && key !== 'sh_time' && key !== 'casestudyname') {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
      //   isValid = false;
      //   errors[key] = 'Invalid phone number';
      // }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }

  const [otpIn, setOtpin] = useState({
    otpIn_first: "",
    otpIn_secon: "",
    otpIn_thir: "",
    otpIn_forth: "",
    otpIn_fifth: "",
    otpIn_six: "",
    error_first: 1,
    error_secon: 1,
    error_thir: 1,
    error_forth: 1,
    error_fifth: 1,
    error_six: 1,
    signIn_err: '0',
    signIn_mesage: '',
    status: 'fail',
    id: ''
  });
  const inputEventOtpIn = (event) => {
    console.log(event);
    setOtpin((preValue) => {

      if (event.target.name === 'otpIn_first') {

        return {
          otpIn_first: event.target.value,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: 0,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_secon') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: event.target.value,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: 0,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_thir') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: event.target.value,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: 0,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }

      if (event.target.name === 'otpIn_forth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: event.target.value,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: 0,
          error_fifth: preValue.error_fifth,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }



      if (event.target.name === 'otpIn_fifth') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: event.target.value,
          otpIn_six: preValue.otpIn_six,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: 0,
          error_six: preValue.error_six,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };

      }
      if (event.target.name === 'otpIn_six') {

        return {
          otpIn_first: preValue.otpIn_first,
          otpIn_secon: preValue.otpIn_secon,
          otpIn_thir: preValue.otpIn_thir,
          otpIn_forth: preValue.otpIn_forth,
          otpIn_fifth: preValue.otpIn_fifth,
          otpIn_six: event.target.value,
          error_first: preValue.error_first,
          error_secon: preValue.error_secon,
          error_thir: preValue.error_thir,
          error_forth: preValue.error_forth,
          error_fifth: preValue.error_fifth,
          error_six: 0,
          signIn_err: preValue.signIn_err,
          signIn_mesage: '',
          status: 'fail',
          id: preValue.id,
        };
      }
    });
    if (event.target.name === 'otpIn_first') {

      document.getElementById('otpIn_secon').focus();
    }

    if (event.target.name === 'otpIn_secon') {

      document.getElementById('otpIn_thir').focus();
    }
    if (event.target.name === 'otpIn_thir') {


      document.getElementById('otpIn_forth').focus();
    }

    if (event.target.name === 'otpIn_forth') {


      //   document.getElementById('otpIn_fifth').focus();
    }



    //      if(event.target.name==='otpIn_fifth'){


    //           document.getElementById('otpIn_six').focus();
    //  }
    console.log(otpIn);

  }
  React.useEffect(() => {
    if (otpIn.otpIn_first != '' && otpIn.otpIn_secon != ''
      && otpIn.otpIn_thir != '' && otpIn.otpIn_forth != '') {
        handleSubmitotp();
    }
 
 }, [otpIn]);

  const handleSubmitotp = (event) => {

    setErrorMessage('');
    setSuccessMessage('');
    if (otpIn.otpIn_first == '' || otpIn.otpIn_secon == ''
      || otpIn.otpIn_thir == '' || otpIn.otpIn_forth == '') {
      setErrorMessage('Please Enter Valid otp');
      return false;
    }
    var otp = otpIn.otpIn_first + otpIn.otpIn_secon + otpIn.otpIn_thir + otpIn.otpIn_forth + otpIn.otpIn_fifth + otpIn.otpIn_six;

    setSubmitting(true);
    otpUpdate({ id: otpid, otp: otp })
      .then(res => {
        console.log(res.data);

        if (res.data.status == true) {
          // setOpen(true);
          setOpenotp(false);
          setSubmitting(false);
          if(pageselect=='leader'){
            navigate('/thankyou/talktoleadership');
          }
          // setTimeout(() => {
          //   setOpen(false);
          // }, 3000);






        }
        else {

          setErrorMessage(res.data.message);
          setOtpin((preValue) => {

            return {
              otpIn_first: '',
              otpIn_secon: '',
              otpIn_thir: '',
              otpIn_forth: '',
              otpIn_fifth: '',
              otpIn_six: '',
              error_first: 0,
              error_secon: 0,
              error_thir: 0,
              error_forth: 0,
              error_fifth: 0,
              error_six: 0,

            };
          })
          setSubmitting(false);
        }
      });
  }
  return (
    <>
      <Header />
      {
        aboutData?.aboutbanner &&
        <div className='main-sec-homes about-main'>
          <div class="main-sec-home about-sec-banner" title="About Us Banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${aboutData.aboutbanner.banner_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>
          
              <h1>{aboutData.aboutbanner.banner_title}</h1>

              <p className='head-para'>{aboutData.aboutbanner.banner_title_short}</p>

            </div>
          </div>
        </div>
      }

{
        aboutData?.aboutbanner &&
        <div className='main-sec-homes about-main mob-sec-new'>
          <div class="main-sec-home about-sec-banner" title="About Us Banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${aboutData.aboutbanner.banner_mobile_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>
            {/* <ScrollAnimation animateIn="fadeIn"> */}
              <h1>{aboutData.aboutbanner.banner_title}</h1>

              <p className='head-para'>{aboutData.aboutbanner.banner_title_short}</p>
{/* </ScrollAnimation> */}
            </div>
          </div>
        </div>
      }

      {
        aboutData?.aboutpage &&
        <>
          <div className='expert-sec'>
            <div className='container'>
              <div className='row'>
             
                <h2>{aboutData.aboutpage.page_title}</h2>
                <img src= {process.env.REACT_APP_IMAGE_URL +aboutData.aboutpage.image} alt={`Our Expertise Infographics`}/>
                <p>{aboutData.aboutpage.page_short_des}</p>
                <div className='button-sec servic-btn-sec leader-sec'>
                  
                    <a  onClick={handleClickOpenCaseyes} ><span>Talk to our Leadership Team</span><i class="fa-solid fa-arrow-right-long arrow-one"></i></a>
                    </div>
              </div>
            </div>
          </div>

          {/* check services section start */}
          <div className='vision-sec servic-visi'>
            <div className='container'>
              <div className='row'>

                <div className='col-md-12'>
                  <div className='vision-inner servic-inner-sec'>
                  <ScrollAnimation animateIn="fadeIn">
                    <p>
                    {aboutData.aboutpage.page_des}
                    </p>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn">
                    <div className='button-sec servic-btn-sec'>
                    <Link to="/services"> <button className='btn btn-sec'>CHECK OUT OUR SERVICES</button></Link> 
                    </div>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* cgeck services section  section end */}

        </>
      }



      <Footer />
      
      <BootstrapDialog
        onClose={handleClosecaseyes}
        aria-labelledby="customized-dialog-title"
        open={opencaseyes} className="modal-casestudy modal-yes happy-modal modal-cases final-modal"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClosecaseyes}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Happy to help!</h2>
            {/* <p>Let us send you more case studies</p> */}

            <div className="form-sec-main">
              <div className='form-sec form-modal'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Full Name" variant="standard"
                    name="name"
                    className={classNames("form-control", { 'is-invalid': formErrors.name })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.name}

                  />
                  {
                    formErrors.name &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.name}
                      </div>
                    </div>
                  }
                </Box>
              </div>
              <div className='form-sec form-modal'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Email Id" variant="standard"
                    name="email"
                    className={classNames("form-control", { 'is-invalid': formErrors.email })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.email}

                  />
                  {
                    formErrors.email &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.email}
                      </div>
                    </div>
                  }
                </Box>
                <p className='veri-secss'><span>This email will be verified</span></p>

              </div>
              <div className='form-sec form-modal'>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                  }}
                  noValidate
                  autoComplete="off"
                >

                  <TextField id="standard-basic" label="Your Contact Number" variant="standard"

                    name="phone"
                    className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                    onChange={handleInputChange}
                    style={{ backgroundImage: 'none' }}
                    value={formValues.phone}
                  />
                  {
                    formErrors.phone &&
                    <div className="invalid-feedback">
                      <div className='error-msg'>
                        {formErrors.phone}
                      </div>
                    </div>
                  }
                </Box>

                <div class="tooltipnew"> <i class="fa-solid fa-info-circle"></i>
                  <span class="tooltiptext">In case we need to contact you</span>
                </div>

              </div>

              <div className='form-sec form-modal form-radio-sec'>


              <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" className="contactng-sec">You are contacting us for</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"

                    name="radio-buttons-group"
                    style={{ backgroundImage: 'none' }}
                  >
                    <FormControlLabel value="you need more case studies"
                      checked={formValues.contact_us == 'you need more case studies'}
                      name="contact_us"
                      onChange={handleInputChange}
                      control={<Radio />} label="You need more case studies"

                    />
                    {/* <FormControlLabel value="You are interested in a product demo"
                      name="contact_us"
                      checked={formValues.contact_us == 'You are interested in a product demo'}
                      onChange={handleInputChange}
                      control={<Radio />} label="You are interested in a product demo" /> */}
                    <FormControlLabel value="you want us to send you our company presentation"
                      name="contact_us"
                      checked={formValues.contact_us == 'you want us to send you our company presentation'}
                      onChange={handleInputChange}
                      control={<Radio />} label="You want us to send you our company presentation" />
                    <FormControlLabel value="you want a representative to call you"
                      checked={formValues.contact_us == 'you want a representative to call you'}
                      name="contact_us"
                      onChange={handleInputChange}
                      control={<Radio />} label="You want a representative to call you" />

                  </RadioGroup>
                </FormControl>





                {
                  formErrors.contact_us &&
                  <div className="invalid-feedbackcontact">
                    <div className='error-msg'>
                      {formErrors.contact_us}
                    </div>
                  </div>
                }

              </div>
              <div className='form-sec form-modal'>

                <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
              </div>
              <div className='form-sec form-modal' >


                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                />
                {
                  formErrors.recaptcha &&
                  <div className="invalid-feedback" style={{ display: 'block' }}>
                    <div className='error-msg'>
                      {formErrors.recaptcha}
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className='button-sec button-case'>

              <a className='btn btn-sec btn-yes' onClick={handleSubmit}>Submit

                {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>}
              </a>
            </div>
            <div className='validate-sec validate-sec-mode'>
              {
                successMessage &&
                <div className="success-msg-sec">
                  <p className="succ-msg">{successMessage}
                  </p>
                </div>
              }
              {
                errorMessage &&
                <div className="error-msg-sec">
                  <p className="error-msg">{errorMessage}
                  </p>
                </div>
              }
            </div>
          </Typography>

        </DialogContent>

      </BootstrapDialog>


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="modal-casestudy contact-thanku modal-thanku-connet"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Thank You !!</h2>
            <p>Your message is successfully delivered to the concerned team, they will do the needful soon</p>


          </Typography>

        </DialogContent>

      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleCloseotp}
        aria-labelledby="customized-dialog-title"
        open={openotp} className="modal-casestudy modal-yes happy-modal otp-modal-new"
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleCloseotp}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers className="new-otp">
          <Typography gutterBottom >
            <p className="otp-head">Please check your spam or junk folder, if your authentication code is not visible in your inbox
            </p>

            <div className="form-sec-main otp-main-secss">

              <div className='form-sec form-modal'>
                <div className='otp-form form__group inputs d-flex flex-row justify-content-center mt-2'>
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_first" id="otpIn_first" onChange={inputEventOtpIn} value={otpIn.otpIn_first} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_secon" id="otpIn_secon" onChange={inputEventOtpIn} value={otpIn.otpIn_secon} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_thir" id="otpIn_thir" onChange={inputEventOtpIn} value={otpIn.otpIn_thir} />
                  <input class="text-center form-control rounded otp-sec-input" type="text" maxlength="1" name="otpIn_forth" id="otpIn_forth" onChange={inputEventOtpIn} value={otpIn.otpIn_forth} />
                </div>



              </div>
              <div className='button-sec button-case btn-otp'>

                <a className='btn btn-sec btn-yes btn-sub' onClick={handleSubmitotp}>Submit

                  {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>}
                </a>
              </div>
              <div className='validate-sec validate-sec-mode width-otp'>
                {
                  successMessage &&
                  <div className="success-msg-sec">
                    <p className="succ-msg">{successMessage}
                    </p>
                  </div>
                }
                {
                  errorMessage &&
                  <div className="error-msg-sec">
                    <p className="error-msg">{errorMessage}
                    </p>
                  </div>
                }
              </div>
            </div>
          </Typography>

        </DialogContent>

      </BootstrapDialog>
    </>
  )
}
export default Aboutus;
