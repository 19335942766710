import React from 'react';
import Footer from './footer';
import Header from './header';
import efficiencyimpimg from '../assets/img/efficency_audit_new.png';
import gapbenefitsimg from '../assets/img/gap-benefits-img.png';
import ScrollAnimation from 'react-animate-on-scroll';

const Efficiencyaudit = () => {
    return (
        <>
            <Header />
            <div className='main-sec-homes about-main effice-head'>
                <div class="main-sec-home about-sec-banner effice-bg" style={{ backgroundImage: `url(${require("../assets/img/Efficiency-Audit-banner.jpg")})` }}>

                </div>
                <div className='container'>
                    <div className='banner-content abut-banner-cont'>
                        <h1>Efficiency Audit</h1>

                        {/* <p className='head-para'>Delivering client-centric solutions for 12 years</p> */}

                    </div>
                </div>
            </div>

            <div className='servicess-inner-sec bg-sec-new'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='servicess-inner-para'>
                                <p>We refine your existing processes for efficiency
                                    by identifying clear opportunities and providing an automated strategy and roadmap to ensure minimal interruption across all systems.</p>
                            </div>
                        </div>
                    </div>
                    <div className='servicess-divid'>
                        <div className='row'>
                            <div className='col-md-5'>
                                <ScrollAnimation animateIn="fadeIn">
                                    <div className='srvicess-left'>
                                        <img src={efficiencyimpimg} />
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-md-7'>
                                <div className='srvicess-right'>
                                    <ScrollAnimation animateIn="fadeIn">
                                        <h2>Its Importance</h2>
                                        <p>Our audit identifies where your institution's few processes can transform. By conducting the audit, we ensure that the workflows are responsive and resilient.</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>

                        <div className='point-sec-service'>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className='srvicess-right'>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <h2>It’s Benefits</h2>
                                            <ul>
                                                <li>Improved Workflow Visibility</li>
                                                <p>  Clear, automated workflows reduce the complexity of day-to-day operations.</p>
                                                <li>Enhanced Collaboration</li>
                                                <p> Improved communication and collaboration across departments,</p>
                                                <li>Future-Ready Processes</li>
                                                <p>  Scalability built into the new workflows ensures your institution's long-term success.</p>
                                            </ul>
                                        </ScrollAnimation>
                                    </div>

                                </div>
                                <div className='col-md-5'>


                                    <div className='srvicess-left point-srvicess-left'>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <img src={gapbenefitsimg} />
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Efficiencyaudit;
