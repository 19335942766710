import React from 'react';
import Footer from './footer';
import Header from './header';
import databaseimg from '../assets/img/data-base.png';
import gapbenefitsimg from '../assets/img/gap-benefits-img.png';
import ScrollAnimation from 'react-animate-on-scroll';

const Njsmartcollection = () => {
    return (
        <>
            <Header />
            <div className='main-sec-homes about-main  workflow-bg-img njsmart-bg-head'>
                <div class="main-sec-home about-sec-banner njsmart-bg" style={{ backgroundImage: `url(${require("../assets/img/inner-banner-v-news.jpg")})` }}>

                </div>
                <div className='container'>
                    <div className='banner-content abut-banner-cont workflow-content'>
                        <h1>New Jersey Data Submission         </h1>

                        {/* <p className='head-para'>Delivering client-centric solutions for 12 years</p> */}

                    </div>
                </div>
            </div>

            <div className='servicess-inner-sec bg-sec-new'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='servicess-inner-para'>
                                <p>We streamline your district's data submissions across multiple platforms required by the New Jersey Department of Education (DOE).
                                    We oversee the critical processes involved in NJ SMART and NJ Homeroom for academic data management,
                                    NJ institution maintain its QSAC qualification.</p>
                            </div>
                        </div>
                    </div>
                    <div className='servicess-divid'>
                        <div className='row'>
                            <div className='col-md-5'>
                                <ScrollAnimation animateIn="fadeIn">
                                    <div className='srvicess-left'>
                                        <img src={databaseimg} />
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-md-7'>
                                <div className='srvicess-right'>
                                    <ScrollAnimation animateIn="fadeIn">
                                        <h2>Its Importance</h2>
                                        <p>We understand the importance of the timely submission of accurate data. We make sure you avoid shortfalls and potential legal risks by maintaining compliance with state regulations and QSAC standards. </p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>

                        <div className='point-sec-service'>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className='srvicess-right'>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <h2>It’s Benefits</h2>
                                            <ul>
                                                <li>Accurate Data Management</li>
                                                <p>Ensure compliance with NJ SMART and NJ Homeroom, maintaining up-to-date and precise educational records.</p>
                                                <li>Secured State Funding</li>
                                                <p> Guarantee accurate NJ ASSA reporting to secure the necessary funding for your district, particularly for supporting low-income students</p>
                                                <li>Legal Compliance</li>
                                                <p>  Maintain compliance with NJ Department of Labor regulations, protecting your institution from legal risks.</p>
                                            </ul>
                                        </ScrollAnimation>
                                    </div>

                                </div>
                                <div className='col-md-5'>


                                    <div className='srvicess-left point-srvicess-left'>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <img src={gapbenefitsimg} />
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Njsmartcollection;
