import React from "react";

const Preloader = () => {
  return (
    <>
   
   <span class="loader"></span>
      
      {/* <div className="loader-wrapper">
        <div className="loader"></div>
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div> */}
    </>
  );
};

export default Preloader;
