import React from 'react';
import Header from './header';
import Footer from './footer';
import njamsrtimg from '../assets/img/njsmart_img.png';
import gapimmg from '../assets/img/gap-analysis_img.png';
import effimg from '../assets/img/efficency_img.png';
import programmeimg from '../assets/img/programming_img.png';

import workflowimg from '../assets/img/workflow_auto_img.png';
import { useSelector } from "react-redux";
import classNames from "classnames";
import ScrollAnimation from 'react-animate-on-scroll';
import {
    Link,
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useNavigate
} from "react-router-dom";

function isOdd(num) { return num % 2; }
const Services = () => {
    const { serviceData } = useSelector((state) => state.pageContents);

    return (
        <>
            <Header />
            {
                serviceData?.servicebanner &&
                <div className='main-sec-homes about-main'>
                    <div class="main-sec-home about-sec-banner" title="Services Banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${serviceData.servicebanner.banner_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont '>
                            <h1>{serviceData.servicebanner.banner_title}</h1>

                            <p className='head-para'>{serviceData.servicebanner.banner_title_short}</p>
                            {/* <div class="button-sec service-btn-sec"><a class="btn btn-sec sev-banr-btn" href="/">Get in Touch</a></div> */}
                        </div>
                    </div>
                </div>
            }
            {
                serviceData?.servicebanner &&
                <div className='main-sec-homes about-main mob-sec-new'>
                    <div class="main-sec-home about-sec-banner" title="Services Banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${serviceData.servicebanner.banner_mobile_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont '>
                            <h1>{serviceData.servicebanner.banner_title}</h1>

                            <p className='head-para'>{serviceData.servicebanner.banner_title_short}</p>
                            {/* <div class="button-sec service-btn-sec"><a class="btn btn-sec sev-banr-btn sev-mob-btn" href="/">Get in Touch</a></div> */}
                        </div>
                    </div>
                </div>
            }

            <div className='servic-dis-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-5 service-full-width'>
                            <div className='servic-dis-inner'>
                                {/* <ScrollAnimation animateIn="fadeIn"> */}
                                {
                                    serviceData?.servicepage &&
                                    <h2>{serviceData.servicepage.page_title}</h2>
                                }
                                {/* </ScrollAnimation> */}

                            </div>
                        </div>

                        <div className='col-md-7 service-full-width'>
                            <div className='servics-sec'>

                                {
                                    serviceData?.services.length &&
                                    <>
                                        {
                                            serviceData.services.map((ser, index) => (
                                                <>
                                                    {(index == 4) && <div className='servic-njsmart-sec servic-njsmart-sec-new '>
                                                        {/* <ScrollAnimation animateIn="fadeIn"> */}
                                                        <Link to={`/${ser.slug}`}>
                                                            <img src={process.env.REACT_APP_IMAGE_URL + ser.image}  
                                                              alt={`${ser.id==1 ? 'Workflow Management & Automation as a Service to K12 Schools in New Jersey - Icon'  :''} ${ser.id==2 ? 'Gap_Analysis as a Service to K12 Schools in New Jersey - Icon'  :''}  ${ser.id==3 ? 'Efficiency_Audit as a Service to K12 Schools in New Jersey - Icon'  :''} ${ser.id==4 ? 'Software Development as a Service to K12 Schools in New Jersey - Icon'  :''} ${ser.id==5 ? 'Reporting at NJDOE as a service for K12 schools in new jerset'  :''}`} 
                                                            />
                                                            <p>{ser.title}</p>
                                                            <div className='project-acn-sec service-iew-sec'>
                                                                <div className='proj-actn-left serv-left-view'>
                                                                    <a ><span>Learn More</span><i class="fa-solid fa-arrow-right-long"></i></a>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        {/* </ScrollAnimation> */}
                                                    </div>}

                                                </>
                                            ))
                                        }
                                    </>

                                }
                                <div className='servic-other-sec'>

                                    {
                                        serviceData?.services.length &&
                                        <>
                                            {
                                                serviceData.services.map((ser, index) => (
                                                    <>
                                                        {(index != 4) && <div className={`gap-sec gap-new-sec ${(isOdd((index + 1)) == 0) && 'eff-sec'}  ${index == 1 && 'serveff-se'}`}>
                                                            {/* <ScrollAnimation animateIn="fadeIn"> */}
                                                            <Link to={`/${ser.slug}`}>
                                                                <img src={process.env.REACT_APP_IMAGE_URL + ser.image} 
                                                                alt={`${ser.id==1 ? 'Workflow Management & Automation as a Service to K12 Schools in New Jersey - Icon'  :''} ${ser.id==2 ? 'Gap_Analysis as a Service to K12 Schools in New Jersey - Icon'  :''}  ${ser.id==3 ? 'Efficiency_Audit as a Service to K12 Schools in New Jersey - Icon'  :''} ${ser.id==4 ? 'Software Development as a Service to K12 Schools in New Jersey - Icon'  :''} ${ser.id==5 ? 'Reporting at NJDOE as a service for K12 schools in new jerset'  :''}`} 
                                                                />
                                                                <p>{ser.title}</p>
                                                                <div className='project-acn-sec service-iew-sec small-serv-left-view'>
                                                                    <div className='proj-actn-left serv-left-view smal-left-sec'>
                                                                        <a ><span>Learn More</span><i class="fa-solid fa-arrow-right-long"></i></a>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            {/* </ScrollAnimation> */}
                                                        </div>}

                                                    </>
                                                ))
                                            }
                                        </>

                                    }






                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Services;