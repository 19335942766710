import React, { useState, useEffect } from "react";
// import { useRouter } from "next/router";
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate
} from "react-router-dom";
import imglogo from '../assets/img/logo.png';
import callimg from '../assets/img/call.png';
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Helmet } from 'react-helmet';
const Header = () => {
  let navigate = useNavigate();
  const { contactData, homeData } = useSelector((state) => state.pageContents);
  console.log(contactData);
  const [activeMenus, setActiveMenus] = useState("home");
  const handleclickmenu = (e) => {

    if (e == "home") {
      navigate(`/`);
    } else if (e == "aboutus") {
      navigate(`/aboutus`);
    } else if (e == "services") {
      navigate(`/services`);
    } else if (e == "project") {
      navigate(`/project`);
    }
    else if (e == "career") {
      navigate(`/career`);
    } else if (e == "contact") {
      navigate(`/contact`);
    }
    setActiveMenus(e);
  };
  React.useEffect(() => {
    var pathname = window.location.pathname;


    if (window.location.pathname == "/") {
      setActiveMenus("home");

    } else if (window.location.pathname == "/aboutus") {
      setActiveMenus("aboutus");

    } else if (window.location.pathname == "/services") {
      setActiveMenus("services");

    } else if (window.location.pathname == "/project") {
      setActiveMenus("project");

    }
    else if (window.location.pathname == "/contact") {
      setActiveMenus("contact");

    }
    else if (window.location.pathname == "/career") {
      setActiveMenus("career");

    }
    else if (pathname.includes('/casestudies') == true) {
      setActiveMenus("casestudies");

    }
    else if (window.location.pathname != "/privacy-policy" && window.location.pathname != "/terms-condition" && pathname.includes('/thankyou') == false && pathname.includes('/bookademo') == false) {
      setActiveMenus("services");

    } else if (pathname.includes('/casestudies') == true) {
      setActiveMenus("casestudies");

    } else if (window.location.pathname == "/privacy-policy") {
      setActiveMenus("");

    } else if (window.location.pathname == "/terms-condition") {
      setActiveMenus("");

    }
  }, [window.location.pathname]);

  return (
    <div>
      <Helmet>
      {activeMenus =='home' && <title>Best K12 School Management System in New Jersey, USA | Appostrophi</title> }
        {activeMenus =='home' && <meta name="description" content="Appostrophi drives digital transformation for K-12 schools in New Jersey, delivering solutions from gap analysis to programming that automates and modernizes workflows" />}
        {activeMenus =='home' &&    <meta name="keyword" content="K12 education software,School management system,workflow automation tools,gap analysis report,efficiency audit,programming tools,new jersey department of education,doe new jersey,nj smart,security systems" />}

        {activeMenus =='aboutus' &&<title>Leveraging over 12 years of expertise to streamline workflows in k12 schools</title> }
        {activeMenus =='aboutus' && <meta name="keyword" content="School management software,K12 education software,student information system software,school management system,education ERP solutions,academic management software" /> }
        {activeMenus =='aboutus' && <meta name="description" content="With a focus on K12 education software and school management systems, we deliver solutions with modernization" /> }
         
      
      
        {activeMenus == 'services' &&<title>Delivering our software solutions, workflow management, and audits that empower K-12 schools digitally</title> }

        {activeMenus == 'services' &&<meta name="keyword" content="School management software,K12 education software,student information system software,school management system,education ERP solutions,academic management software,k 12 erp systems,best erp for educational,institutions" /> }
        {activeMenus =='services' && <meta name="description" content="From education ERP solutions to student information systems our services are customized for K12 school" /> }
                


        {activeMenus == 'project' &&<title>Our school management software projects demonstrated how K-12 schools automated through education ERP solutions.</title>}

        {activeMenus == 'project' &&<meta name="keyword" content="School management software,K12 education software,student information system software,school management system,education ERP solutions,academic management software,k 12 erp systems,best erp for educational,institutions" />}
        {activeMenus =='project' && <meta name="description" content="Our school management software projects demonstrate how K12 schools are improving efficiency with education ERP solutions." /> }
          

        {activeMenus == 'casestudies' &&<title>Our case studies with an efficient K 12 erp system transformed traditional processes into digitalization.</title>}

        {activeMenus == 'casestudies' &&<meta name="keyword" content="School management software,K12 education software,student information system software,school management system,education ERP solutions,academic management software,k 12 erp systems,best erp for educational,institutions" />}
        {activeMenus =='casestudies' && <meta name="description" content="Revealing the success stories behind our student information system software and school management software through detailed case studies." /> }
        </Helmet>
        <div className='main-sec-header'>
        <div className='container'>
          <div className='row'>

          <nav class="navbar navbar-expand-lg navbar-light ">
            <Link class="navbar-brand" to="/">
              <img src={imglogo} alt='logo' />
            </Link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mb-2 mb-lg-0">
                <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("home")}>
                  <Link to="/" className={`nav-link ${activeMenus == 'home' && 'active'}`} ><i class="fa-solid fa-house"></i></Link>

                </li>
                <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("aboutus")}>
                  <Link to="/aboutus" className={`nav-link nav-new ${activeMenus == 'aboutus' && 'active'}`} >About Us</Link>


                </li>
                <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("services")} >
                  <Link to="/services" className={`nav-link nav-new ${activeMenus == 'services' && 'active'}`} >Services</Link>
                  
                  </li>




                <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("project")}> 
                <Link to="/project" className={`nav-link nav-new ${activeMenus == 'project' && 'active'}`}>Projects</Link></li>
                
                <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("casestudies")}>
                  {
                    homeData?.projects.length &&
                    <Link to={`/casestudies/${homeData.projects[0].slug}`} className={`nav-link nav-new ${activeMenus == 'casestudies' && 'active'}`}>Case Studies</Link>
                  }

                </li>
                <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("career")}> 
                <Link to="/career" className={`nav-link nav-new ${activeMenus == 'career' && 'active'}`}>Careers</Link></li>
                <li class="nav-item nav-new-menu" onClick={(e) => handleclickmenu("contact")}> 
                {/* <Link to="/contact" className={`nav-link nav-last ${activeMenus == 'contact' && 'active'}`}>Contact Us</Link></li> */}
                <Link to="/contact" className={`nav-link nav-new  ${activeMenus == 'contact' && 'active'}`}>Contact Us</Link></li>
                
      

                 



                </ul>
                {/* <form class="d-flex">
                <a class="btn btn-call mob-no" href={`tel:${contactData.contactpage.phone}`}>
                  <img src={callimg} className='imgwhite' />


                  {
                    contactData?.contactpage &&
                    contactData.contactpage.phone
                  }</a>
              </form> */}
              </div>
            </nav>

          </div>

      </div>
     </div>
     </div>

  )
}
export default Header;
