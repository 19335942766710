import React, { useState, useRef, useEffect } from "react";
import logo from '../assets/img/logo.png';
import jersyimg from '../assets/img/njsmart_img-color.png';
import objimg from '../assets/img/objective_img.png';
import resolimg from '../assets/img/resol-_img.png';
import outcomeimg from '../assets/img/outcome_img.png';
import { useSelector } from "react-redux";
import classNames from "classnames";

 const Casestudypdf = () => {
  const { casestudiesData } = useSelector((state) => state.pageContents);
  return (
    <>
    <div className='bg-sec-newss-pdf'>
    <div className='casestudy-pdf-main'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='casestudypf-head'>
                <img src={logo} />
                <h1>Case Studies</h1>
                <p>The Measurable Yardsticks To Our Cause</p>
              </div>
              <div className='casestudy-jersy'>
                <div class="interven-sec pdf-interven-sec">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="new-image pdf-new-image">
                          <div class="new-imagess pdf-new-imagess">
                            <h3>Our interventions at the largest public school district in New Jersey </h3>
                              <img src={jersyimg} />
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
          casestudiesData?.casestudies.length &&
          <>
            {
              casestudiesData.casestudies.map((pro, index) => (
                <>
                {pro.first_title !=null &&
                <>
              <div className='pagebrpr'>
              <div className='case-stud-sec case-stud-sec-pdf ' >
                        <div className='container'>
                          <div className='row'>
                            <div className='col-md-12 case-new-mar case-new-mar-pdf'>
                              <div className='case-stud-sec-inner case-new-insd case-new-insd-pdf'>
                              <h3>{pro.title}</h3>
                              </div>
                            </div>
                          </div>

                          
                          <div className='row'>
                            <div className='col-md-12'>
                              <div className='case-stud-data-sec case-stud-data-sec-pdf'>
                                <div className='case-stud-icon-sec case-stud-icon-sec-pdf'>
                                
                                    <div className='case-img case-img-pdf'>
                                      <img src={objimg} />
                                    </div>
                                    <div className='case-heading case-heading-pdf'>
                                      <h4>{pro.first_title}</h4>
                                    </div>
                        
                                </div>
                              </div>
                            </div>
                            <div className='col-md-12'>
                              <div className='case-stud-data case-stud-data-pdf'>
                              
                              <p>{pro.first_des}</p>
                              </div>
                            </div>
                          </div>


                          <div class="row margn-case margn-case-pdf">
                            <div class="col-md-12">
                              <div class="case-stud-data-sec case-stud-data-sec-pdf">
                                <div class="case-stud-icon-sec case-stud-icon-sec-pdf">
                                  <div class="case-img case-img-pdf">
                              <img src={resolimg} alt="Resolution" />
                              </div><div class="case-heading case-heading-pdf">
                                <h4>{pro.second_title}</h4>
                                </div>
                                </div>
                                </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="case-stud-data case-stud-data-pdf">
                                  {pro.second_des != null && pro.second_des != '' &&
                                    <div dangerouslySetInnerHTML={{ __html: pro.second_des }}></div>

                                  }

                                  </div>
                                </div>
                          </div>


                          <div className='row margn-case margn-case-pdf'>
                            <div className='col-md-12'>
                              <div className='case-stud-data-sec case-stud-data-sec-pdf'>
                                <div className='case-stud-icon-sec case-stud-icon-sec-pdf'>
                                 
                                    <div className='case-img case-img-pdf'>
                                      <img src={outcomeimg}   />
                                    </div>
                                    <div className='case-heading case-heading-pdf'>
                                      <h4>{pro.third_title}</h4>
                                    </div>
                               
                                </div>
                              </div>
                            </div>
                            <div className='col-md-12'>
                            <div class="case-stud-data case-stud-data-pdf">
                            {pro.third_des != null && pro.third_des != '' &&
                                    <div dangerouslySetInnerHTML={{ __html: pro.third_des }}></div>

                                  }
                          </div>
                            </div>
                          </div>


                        </div>
                      </div>


                      </div>
                       </>
                      }
    
                      </>
              ))
            }
          </>

        }
                    
            </div>
          </div>
        </div>
    </div>
    </div>
    </>
  )
}
export default Casestudypdf;
