import React, { useState, useRef, useEffect } from "react";
import Footer from './footer';
import Header from './header';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import arrowimg from '../assets/img/arrow.png';
import checkimg from '../assets/img/check-right.png';
import {
  Link,
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import ScrollAnimation from 'react-animate-on-scroll';


// modal code start here

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// modal code end here
// modal code start here
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
// modal code end here
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))
  (({ theme }) =>
  ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  })
  );

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



const Career = () => {
  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    if(panel=='panel0'){
    setExpanded(newExpanded ? panel : false);
    }
  };
  const { careerData } = useSelector((state) => state.pageContents);
  console.log(careerData);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Header />
      {
        careerData?.careerbanner &&
        <div className='main-sec-homes about-main carrernewbg-sec'>
          <div class="main-sec-home about-sec-banner" title="Career Banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${careerData.careerbanner.banner_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>
              <h1>{careerData.careerbanner.banner_title}</h1>
              <p className='head-para'>{careerData.careerbanner.banner_title_short}</p>

            </div>
          </div>
        </div>
      }
 {
        careerData?.careerbanner &&
        <div className='main-sec-homes about-main carrernewbg-sec  mob-sec-new'>
          <div class="main-sec-home about-sec-banner" title="Career Banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${careerData.careerbanner.banner_mobile_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>
              <h1>{careerData.careerbanner.banner_title}</h1>

              <p className='head-para'>{careerData.careerbanner.banner_title_short}</p>

            </div>
          </div>
        </div>
      }
      <div className='project-sec career-sec career-new-bg'>
        <div className='container'>
          <div className='row'>
          {/* <ScrollAnimation animateIn="fadeIn"> */}
            <div className='proj-sec-para career-para'>
              {
                careerData?.careerpage &&
                <p>{careerData.careerpage.page_title}</p>

              }

            </div>
            {/* </ScrollAnimation> */}
          </div>
          <div className='project-accodion-sec '>
            <div className='row'>

              <div className='col-md-12'>
                {
                  careerData?.careers.length &&
                  <>
                    {
                      careerData.careers.map((car, index) => (
                        <>
                          <Accordion expanded={expanded === 'panel' + index} onChange={handleChange('panel' + index)}>
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                              <Typography>
                                <div className='acordian-head'>
                                  <div className='acordian-head-left'>
                                    <img src={arrowimg} alt='Arrow image' />
                                    {/* <ScrollAnimation animateIn="fadeIn"> */}
                                    <p>{car.title}</p>
                                    {/* </ScrollAnimation> */}
                                  </div>
                                  <div className='acordian-head-right'>
                                  {/* <ScrollAnimation animateIn="fadeIn"> */}
                                    <p>Location - <span>{car.location}</span></p>
                                    <p className="pos-sec">Position - <span>{car.position}</span></p>
                                    {/* </ScrollAnimation> */}
                                  </div>
                                </div>


                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>

                                <p className='accordion-inner-para'>
                                {/* <ScrollAnimation animateIn="fadeIn"> */}
                                  <p>{car.short_des}</p>
                                  {/* </ScrollAnimation> */}

                                  {car.des != null && car.des != '' &&
                                    <div dangerouslySetInnerHTML={{ __html: car.des }}></div>

                                  }
                                   
                                  <div className='apply-sec'>
                                  {/* <ScrollAnimation animateIn="fadeIn"> */}
                                    <p>
                                      Apply at  connect@appostrophi.com
                                    </p>
                                    {/* </ScrollAnimation>
                                    <ScrollAnimation animateIn="fadeIn"> */}
                                    <div className='button-sec apply-btn'>
                                      <button className='btn btn-sec' onClick={handleClickOpen}>Apply Online</button>
                                    </div>
                                    {/* </ScrollAnimation> */}
                                  </div>
                              
                                 
                                </p>


                              </Typography>
                            </AccordionDetails>
                          </Accordion>



                        </>
                      ))
                    }
                  </>

                }

              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {/* modal code start here */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="modal-casestudy modal-career modal-careerss "
      >

        <div className="btn-mdl-sec">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >

            < CancelIcon />

          </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Our hiring is on HOLD</h2>



          </Typography>

        </DialogContent>

      </BootstrapDialog>
      {/* modal code end here   */}
    </>
  )
}

export default Career;