import React from 'react';
import Footer from './footer';
import Header from './header';
import workflowimg from '../assets/img/workflow-new.png';
import gapbenefitsimg from '../assets/img/gap-benefits-img.png';
import ScrollAnimation from 'react-animate-on-scroll';

const Workflow = () => {
    return (
        <>
            <Header />
            <div className='main-sec-homes about-main workflow-bg-img workflow-bg-head'>
                <div class="main-sec-home about-sec-banner workflow-bg" style={{ backgroundImage: `url(${require("../assets/img/Workflow-Management-banner.jpg")})` }}>

                </div>
                <div className='container'>
                    <div className='banner-content abut-banner-cont workflow-content'>
                        <h1>Workflow Management and Automation</h1>

                        {/* <p className='head-para'>Delivering client-centric solutions for 12 years</p> */}

                    </div>
                </div>
            </div>

            <div className='servicess-inner-sec bg-sec-new'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='servicess-inner-para'>
                                <p>Our workflow management and automation transform your complex operations into digitally managed systems from start to finish. </p>
                            </div>
                        </div>
                    </div>
                    <div className='servicess-divid'>
                        <div className='row'>
                            <div className='col-md-5'>
                                <ScrollAnimation animateIn="fadeIn">
                                    <div className='srvicess-left'>
                                        <img src={workflowimg} />
                                    </div>
                                </ScrollAnimation>
                            </div>
                            <div className='col-md-7'>
                                <div className='srvicess-right'>
                                    <ScrollAnimation animateIn="fadeIn">
                                        <h2>Its Importance</h2>
                                        <p>Where your workflows span multiple departments and involve manual handoffs,
                                            our service enhances operational flow within them by improving communication and accountability at every step and reducing the likelihood of errors.</p>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>

                        <div className='point-sec-service'>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className='srvicess-right'>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <h2>It’s Benefits</h2>
                                            <ul>
                                                <li>Consistency and Accuracy</li>
                                                <p> Ensure that each step of the workflow is handled correctly, with less variability and fewer errors.</p>
                                                <li>Improved Communication</li>
                                                <p> Facilitate better coordination within the institution by ensuring that information flows smoothly and reliably.</p>
                                                <li>Scalable Growth</li>
                                                <p> Builds workflows that grow with you, ensuring your processes remain progressive.</p>
                                            </ul>
                                        </ScrollAnimation>
                                    </div>

                                </div>
                                <div className='col-md-5'>


                                    <div className='srvicess-left point-srvicess-left'>
                                        <ScrollAnimation animateIn="fadeIn">
                                            <img src={gapbenefitsimg} />
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Workflow;
