import React, { useState, useRef, useEffect } from "react";
import Header from './header';
import Footer from './footer';
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import InputLabel from '@mui/material/InputLabel';
import { InputAdornment } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Select from '@mui/material/Select';
import ReCAPTCHA from "react-google-recaptcha";
import IconButton from '@mui/material/IconButton';
import {
  Link,
  useParams,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";

import {
  checkIdco,
  bookDemo
} from "../actions/pagecontent.actions";

import classNames from "classnames";
function safeBase64Decode(base64String) {
  try {
    return atob(atob(base64String));
  } catch (error) {
    console.error("Failed to decode Base64 string:", error);
    return null;
  }
}
const Bookademo = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const { projectData } = useSelector((state) => state.pageContents);
  const [submitting, setSubmitting] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  useEffect(() => {

    var test = safeBase64Decode(id);
    var date = new Date();
    setFormdateto(date);
    if (test == null) {
      console.log(test);
      navigate(`/`);
    } else {
      checkIdco({ id: test })
        .then((res) => {
          if (res.data.status) {
            console.log(res.data.data);
            var casedeat = { ...formValues };
            casedeat.casestudyname = '';
            casedeat.name = res.data.data.name;
            casedeat.email = res.data.data.email;
            casedeat.phone = res.data.data.phone;

            casedeat.pagename = 'bookdemomail';
            casedeat.sh_date = '';
            casedeat.sh_time = '01:30 PM';
            casedeat.sh_date_new = null;
            casedeat.sh_time_new = null;
            setFormValues(casedeat);



          } else {
            navigate(`/`);
          }
        })
        .catch((error) => {
          navigate(`/`);

        });
    }
  }, []);
  const handleInputChange = (e) => {
    // alert(e.target.value);

    const { name, value } = e.target;
    console.log(e.target.value);
    console.log(e.target.name);
    setFormValues({ ...formValues, ['casestudyname']: value });
    setFormErrors({ ...formErrors, ['casestudyname']: null });
  }
  const [opendate, setOpenDate] = useState(false); // State to control the open/close
  const handleIconClick = () => {
     setOpenDate(true); // Open the picker when the icon is clicked
   };
 
  const handleDate = (e) => {
    let temp = { ...formValues }

    var d = new Date(e);
    var date;
    var month;
    if (d.getDate() < 10) {
      date = "0" + d.getDate();
    }
    else {
      date = d.getDate();
    }

    if ((d.getMonth() + 1) < 10) {
      month = "0" + (d.getMonth() + 1);
    }
    else {
      month = (d.getMonth() + 1);
    }
    var datestring = d.getFullYear() + "-" + month + "-" + date;
    temp.sh_date = datestring;
    temp.sh_date_new = e;

    console.log(datestring);
    setFormErrors({ ...formErrors, ['sh_date']: null });
    setFormValues(temp);
    setOpenDate(false);
  }



  const handleTime = (e) => {
    let temp = { ...formValues }
    console.log(e);
    var d = new Date(e);
    var hour;
    var hours;
    var mintues;
    hours = d.getHours();
    hour = d.getHours();
    mintues = d.getMinutes();
    console.log(hour);

    hour = hour % 12;
    hour = hour ? hour : 12;
    temp.sh_time = addZero(hour) + ':' + addZero(mintues) + ' ' + (hours >= 12 ? 'PM' : 'AM');
    temp.sh_time_new = e;

    console.log(temp.sh_time);
    setFormErrors({ ...formErrors, ['sh_time']: null });
    setFormValues(temp);
  }
  const formFields = {
    name: '',
    email: '',
    phone: '',

    casestudyname: '',
    pagename: 'bookdemomail',

    sh_date: '',
    sh_time: '01:30 PM',

    sh_date_new: null,
    sh_time_new: null

  }
  const [formValues, setFormValues] = React.useState(formFields);
  const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
  const [formdateto, setFormdateto] = useState(null);

  const [pageselect, setPageselect] = React.useState('');

  function addZero(i) {
    if (i < 10) { i = "0" + i }
    return i;
  }

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    console.log(event.target.value);
    setAge(event.target.value);
  };
  const validateForm = () => {
    let isValid = true;
    let errors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] && key !== 'recaptcha' && key !== 'phone' && key !== 'sh_date_new' && key != 'sh_time_new'



      ) {
        isValid = false;
        errors[key] = 'This field is required';
      } else if (key === 'email' && !isEmail(formValues[key])) {
        isValid = false;
        errors[key] = 'Invalid email address';
      }
      // else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
      //   isValid = false;
      //   errors[key] = 'Invalid phone number';
      // }

    });
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      isValid = false;
      errors.recaptcha = 'Please verify captcha';
    }
    console.log(errors);
    setFormErrors(errors);
    return isValid;
  }
  const recaptchaRef = React.useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(false);

    console.log(validateForm());
    if (validateForm()) {
      setSubmitting(true);
      setErrorMessage('');
      setSuccessMessage('');
      let formData = { ...formValues };
      formData.recaptcha = recaptchaRef.current.getValue();

      bookDemo(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.data.status) {

            //setSuccessMessage(response.data.message);
            setFormValues(formFields);
            recaptchaRef.current.reset();

            navigate('/thankyou/bookademoproject');
            // setTimeout(() => {
            //  // setOpen(false);
            // }, 2000);


          } else {
            setErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          setSubmitting(false);
          setErrorMessage('Something went wrong. Please try again later.');
        });
    }
  }
  return (

    <>
      <Header />
      {
        projectData?.projectbanner &&
        <div className='main-sec-homes about-main'>
          <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${projectData.projectbanner.banner_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>
              <h1>Start With a Demo</h1>
              <p className='head-para'>Walk Through Our Solutions Step by Step</p>

            </div>
          </div>
        </div>
      }

      {
        projectData?.projectbanner &&
        <div className='main-sec-homes about-main mob-sec-new'>
          <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${projectData.projectbanner.banner_mobile_image}')` }}>

          </div>
          <div className='container'>
            <div className='banner-content abut-banner-cont'>
              <h1>Start With a Demo</h1>
              <p className='head-para'>Walk Through Our Solutions Step by Step</p>


            </div>
          </div>
        </div>
      }
      <div className='book-sec-project bg-book'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className="bok-iner-head">
                <h2>You are requesting for a demo</h2>
              </div>
              <div className='book-inner-projt final-modal date-modal'>


                <div className="form-sec-main">
                  <div className="form-select-sec">

                    {
                      projectData?.projects.length &&
                      <>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                          <InputLabel id="demo-simple-select-standard-label">Choose Project</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name="casestudyname"
                            value={formValues.casestudyname}
                            onChange={handleInputChange}
                            label="Choose Project"
                          >
                            {projectData.projects.map((pro, index) => (

                              <MenuItem value={pro.title}>{pro.title}</MenuItem>

                            ))}
                          </Select>
                          {
                            formErrors.casestudyname &&
                            <div className="invalid-feedbackcontact">
                              <div className='error-msg'>
                                {formErrors.casestudyname}
                              </div>
                            </div>
                          }
                        </FormControl>
                      </>
                    }




                  </div>


                  <div className='form-sec form-modal date-modal-case data-new-time data-scroll-modal'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={[
                          'DatePicker',
                          'MobileDatePicker',
                          'DesktopDatePicker',
                          'StaticDatePicker',
                        ]}
                      >


                        <MobileDatePicker
                          label="Select Date"
                          variant="standard"
                          inputFormat="MM/dd/yyyy"
                          placeholder="MM/dd/yyyy"
                          value={formValues.sh_date_new}
                          open={opendate} // Control the open state of the picker
                          onClose={() => setOpenDate(false)}
                          onOpen={() => setOpenDate(true)} // Close the picker when it opens via text field

                          onChange={handleDate}
                          minDate={dayjs(formdateto)}
                          renderInput={(params) => <TextField {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleIconClick}>
                                    <CalendarMonthIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}


                          />}
                        />
                        <span className="calend-icon" onClick={handleIconClick}>
                          <CalendarMonthIcon /></span>

                      </DemoContainer>
                    </LocalizationProvider>

                    {
                      formErrors.sh_date &&
                      <div className="invalid-feedbackcontact">
                        <div className='error-msg'>
                          {formErrors.sh_date}
                        </div>
                      </div>
                    }

                  </div>




                  <div className='form-sec form-modal'>
                    <p className="case-privacy info-privacy-sec">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
                  </div>

                  <div className='form-sec form-modal' >
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                    />
                    {
                      formErrors.recaptcha &&
                      <div className="invalid-feedback" style={{ display: 'block' }}>
                        <div className='error-msg'>
                          {formErrors.recaptcha}
                        </div>
                      </div>
                    }
                  </div>

                  <div class="button-sec button-case cse-new"><a class="btn btn-sec btn-yes btn-submit"
                    onClick={handleSubmit}>Submit
                    {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>}
                  </a></div>
                  <div className='validate-sec validate-sec-mode'>
                    {
                      successMessage &&
                      <div className="success-msg-sec">
                        <p className="succ-msg">{successMessage}
                        </p>
                      </div>
                    }
                    {
                      errorMessage &&
                      <div className="error-msg-sec">
                        <p className="error-msg">{errorMessage}
                        </p>
                      </div>
                    }
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Bookademo;
