import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    homeData: null,
    aboutData: null,
    serviceData: null,
    careerData: null,
    casestudiesData: null,
    projectData: null,
    contactData: null,
    privacypolicyData: null,
    cancelpolicyData: null,
}

export const pageContentSlice = createSlice({
    name: 'pageContents',
    initialState,
    reducers: {
        setPageContent: (state, action) => {
            state[`${action.payload.key}Data`] = action.payload.data
        },
    }
})

export const { setPageContent } = pageContentSlice.actions

export default pageContentSlice.reducer
