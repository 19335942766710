import axios from './axios';

export const getHomePageContent = () => {
    return axios.get('/homepage');
}

export const getAboutPageContent = () => {
    return axios.get('/aboutpage');
}

export const getSerCasCarConPageContent = () => {
    return axios.get('/ser-case-car-con');
}
export const addContact = (data) => {
    return axios.post('/contactform', data);
}
export const addConnect = (data) => {
    return axios.post('/happycontform', data);
}
export const otpUpdate = (data) => {
    return axios.post('/connectotpupdate', data);
}
export const checkIdco = (data) => {
    return axios.post('/checkidco', data);
}
export const bookDemo = (data) => {
    return axios.post('/bookdemofrom', data);
}