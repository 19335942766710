import React, { useEffect, useState } from 'react'
import Header from './header';
import Footer from './footer';
import callimg from '../assets/img/call-img.png';
import linkimg from '../assets/img/linked-new.png';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useSelector } from "react-redux";
import classNames from "classnames";
import { isComingSoon, generateUniqueToken } from "../utils/helper";
import {
    addContact
} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

// modal code start here

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

import DialogContent from '@mui/material/DialogContent';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    Navigate,
    Link,
    useNavigate,
    NavLink
} from "react-router-dom";
// modal code end here
// modal code start here
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  // modal code end here

const Contactnew = () => {
    const { contactData } = useSelector((state) => state.pageContents);
    const recaptchaRef = React.useRef();
    const formFields = {
        name: '',
        email: '',
        phone: '',
        // subject: '',
        message: '',
        city: '',
        recaptcha: ''
    }
    const [loader, setLoader] = React.useState(true);

    const { ip_address } = useSelector((state) => state.userdata);
    const [formValues, setFormValues] = React.useState(formFields);
    const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
    const [submitting, setSubmitting] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    let navigate = useNavigate();
    React.useEffect(() => {
        setTimeout(() => setLoader(false), 1500);
        console.log('generateUniqueToken', generateUniqueToken());
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({ ...formErrors, [name]: null });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
      
 setSubmitting(false);
        if (validateForm()) {
            setSubmitting(true);
            setErrorMessage('');
            setSuccessMessage('');
            let formData = { ...formValues };
            formData.recaptcha = recaptchaRef.current.getValue();
            addContact(formData)
                .then((response) => {
                    setSubmitting(false);
                    if (response.data.status) {
                        //setSuccessMessage(response.data.message);
                        setFormValues(formFields);
                        recaptchaRef.current.reset();
                        setOpen(true);
                        setTimeout(() => {
                          setOpen(false);
                      }, 2000);
                       
                    } else {
                        setErrorMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    setSubmitting(false);
                    setErrorMessage('Something went wrong. Please try again later.');
                });
        }
    }

    const validateForm = () => {
        let isValid = true;
        let errors = {};
        Object.keys(formValues).forEach((key) => {
            if (!formValues[key] && key !== 'recaptcha' && key !== 'city') {
                isValid = false;
                errors[key] = 'This field is required';
            } else if (key === 'email' && !isEmail(formValues[key])) {
                isValid = false;
                errors[key] = 'Invalid email address';
            } else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
                isValid = false;
                errors[key] = 'Invalid phone number';
            }
        });
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            isValid = false;
            errors.recaptcha = 'Please verify captcha';
        }
        setFormErrors(errors);
        return isValid;
    }

    // modal section start

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        //setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // modal section end
    const handleregis = () => {
        navigate(`/register`);
    }
    return (
        <>
            <Header />
            {/* <div className='bg-sec-newss career-newbg'> */}
            {
                contactData?.contactbanner &&
                <div className='main-sec-homes about-main'>
                    <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${contactData.contactbanner.banner_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont'>
                       
                            <h1>{contactData.contactbanner.banner_title}</h1>


                        </div>
                    </div>
                </div>
            }
 {
                contactData?.contactbanner &&
                <div className='main-sec-homes about-main  mob-sec-new'>
                    <div class="main-sec-home about-sec-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL}${contactData.contactbanner.banner_mobile_image}')` }}>

                    </div>
                    <div className='container'>
                        <div className='banner-content abut-banner-cont'>
                       
                            <h1>{contactData.contactbanner.banner_title}</h1>


                        </div>
                    </div>
                </div>
            }

            <div className='contact-details career-newbg'>
                <div className='container'>
               
                    <div className='row'>
                        <div className='col-md-12'>
                       
                            <div className='contact-heading'>
                                <h2>
                                    {
                                        contactData?.contactpage &&
                                        contactData.contactpage.page_title
                                    }
                                </h2>

                            </div>
                           
                        </div>

                        
{/*                         
                        {
                            contactData?.contactpage &&
                            <>

                                <div className='col-md-3'>
                                    <div className='contact-info'>
                                        <h2>Contact Info</h2>
                                        <p className='margin-cont-top'><i class="fa-solid fa-location-dot"></i><span>{contactData.contactpage.address}</span></p>
                                        <p><a href={`tel:${contactData.contactpage.email}`}><i class="fa-solid fa-envelope"></i><span>{contactData.contactpage.email}</span>
                                        </a>
                                        </p>
                                        <p><a href={`tel:${contactData.contactpage.phone}`}><img src={callimg} className='img-call' /><span>{contactData.contactpage.phone}</span>
                                        </a></p>

                                        <div className='social-icon'>
                                            <a href={contactData.contactpage.linkdin_link} target='_blank'> <img src={linkimg} /></a>
                                        </div>
                                    </div>

                                </div>
                            </>
                        } */}


                        <div className='col-md-12'>

                            <div className='contact-form contact-form-new'>


                                <div className='form-sec-main'>

                                    <form >
                                        <div className='row'>
                                            <div className='col-md-6 form-sec'>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { m: 1, width: '100%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >

                                                    <TextField id="standard-basic" label="Name" variant="standard"
                                                        name="name"
                                                        className={classNames("form-control", { 'is-invalid': formErrors.name })}
                                                        onChange={handleInputChange}
                                                        value={formValues.name}
                                                        style={{ backgroundImage: 'none' }}
                                                    />
                                                    {
                                                        formErrors.name &&
                                                        <div className="invalid-feedback">
                                                            <div className='error-msg'>
                                                            {formErrors.name}
                                                            </div>
                                                        </div>
                                                    }
                                                </Box>
                                            </div>
                                            <div className='col-md-6  form-sec'>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { m: 1, width: '100%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >

                                                    <TextField id="standard-basic" label="Email Id" variant="standard"

                                                        name="email"
                                                        className={classNames("form-control", { 'is-invalid': formErrors.email })}
                                                        onChange={handleInputChange}
                                                        style={{ backgroundImage: 'none' }}
                                                        value={formValues.email}
                                                    />
                                                    {
                                                        formErrors.email &&
                                                        <div className="invalid-feedback">
                                                             <div className='error-msg'>
                                                            {formErrors.email}
                                                            </div>
                                                        </div>
                                                    }
                                                </Box>
                                            </div>

                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6  form-sec'>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { m: 1, width: '100%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >

                                                    <TextField id="standard-basic" label="Phone No" variant="standard"

                                                        name="phone"
                                                        className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                                                        onChange={handleInputChange}
                                                        style={{ backgroundImage: 'none' }}
                                                        value={formValues.phone}
                                                    />
                                                    {
                                                        formErrors.phone &&
                                                        <div className="invalid-feedback">
                                                             <div className='error-msg'>
                                                            {formErrors.phone}
                                                            </div>
                                                        </div>
                                                    }
                                                </Box>
                                            </div>

                                            <div className='col-md-6  form-sec'>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { m: 1, width: '100%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >

                                                    <TextField id="standard-basic" label="City" variant="standard"
                                                        name="city"
                                                        className={classNames("form-control", { 'is-invalid': formErrors.city })}
                                                        onChange={handleInputChange}
                                                        value={formValues.city}

                                                    />
                                                    {
                                                        formErrors.city &&
                                                        <div className="invalid-feedback">
                                                             <div className='error-msg'>
                                                            {formErrors.city}
                                                            </div>
                                                        </div>
                                                    }
                                                </Box>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-12  form-sec'>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& > :not(style)': { m: 1, width: '100%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                    multiple
                                                >

                                                    <TextField id="standard-basic" label="Message" variant="standard"

                                                        name="message"
                                                        className={classNames("form-control", { 'is-invalid': formErrors.message })}
                                                        onChange={handleInputChange}
                                                        style={{ backgroundImage: 'none' }}
                                                        value={formValues.message} />
                                                    {
                                                        formErrors.message &&
                                                        <div className="invalid-feedback">
                                                            <div className='error-msg'>
                                                            {formErrors.message}
                                                            
                                                            </div>
                                                        </div>
                                                    }
                                                </Box>
                                            </div>

                                        </div>
                                        <div className='row margin-captch' >
                                            <div className="col-lg-12 col-sm-12">
                                                <div className="form-group" style={{ marginBottom: '0px' }}>
                                                    {/* <img src={captcha_new} alt="captcha" style={{ height: '65px' }} /> */}
                                                    <ReCAPTCHA
                                                        ref={recaptchaRef}
                                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                                                    />
                                                    {
                                                        formErrors.recaptcha &&
                                                        <div className="invalid-feedback" style={{ display: 'block' }}>
                                                             <div className='error-msg'>
                                                            {formErrors.recaptcha}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='button-sec submit-sec'>
                                                    <button className='btn btn-sec' onClick={handleSubmit}>SUBMIT
                                                        {submitting && <div className="spinner-border spinner-border-sm" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                    <div className='validate-sec validate-sec-mode'>
                                        {
                                            successMessage &&
                                            <div className="success-msg-sec">
                                            <p className="succ-msg">{successMessage}
                                            </p>
                                          </div>
                                        }
                                        {
                                            errorMessage &&
                                            <div className="error-msg-sec">
                                            <p className="error-msg">{errorMessage}
                                            </p>
                                          </div>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>
                    

                    {/* <div className='row'>
                <div className='col-md-12'>
                    <div className='contact-heading location-sec'>
                        <h2>Our Location</h2>
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3037.9575193489122!2d-74.08822232451735!3d40.40979185606245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2312c525ffcc9%3A0x4388309a75497a10!2s519%20E%20Rd%2C%20Belford%2C%20NJ%2007718%2C%20USA!5e0!3m2!1sen!2sin!4v1724261446549!5m2!1sen!2sin" 
                        width="100%" height="450" style={{border:'0'}} allowfullscreen="" 
                        loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
        </div> */}

        <div className='connect-sec-new'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='contact-info-new'>
                            <p className='mail-sec-new'>
                                <a><i class="fa-solid fa-envelope"></i></a>
                            </p>
                            <p className='connect-mail-new'>connect@appostrophi.com</p>
                        </div>

                    </div>
                    <div className='col-md-6'>
                        <div className='contact-info-new'>
                            <p className='mail-sec-new'>
                                <a><img src={linkimg} className='img-call-new' /></a>
                            </p>
                            <p className='connect-mail-new'>Linkedin</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>

                </div>
            </div>

            {/* <div className='contact-details career-newbg  career-newbgss'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='contact-heading location-sec'>
                            <ScrollAnimation animateIn="fadeIn">
                                <h2>Our Location</h2>
                               

                                {
                                    contactData?.contactpage &&
                                    <iframe
                                        src={contactData.contactpage.map}
                                        width="100%" height="450" style={{ border: '0' }} allowfullscreen=""
                                        loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                }

</ScrollAnimation>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}


            {/* </div> */}


            <Footer />
            {/* modal code start here */}
<BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open} className="modal-casestudy contact-thanku"
      >
     
       <div className="btn-mdl-sec">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
           
          < CancelIcon />
          
        </IconButton>
        </div>
        <DialogContent dividers>
          <Typography gutterBottom>
            <h2>Thank You !!</h2>
            <p>We will get back to you soon</p>

        
          </Typography>
         
        </DialogContent>
       
      </BootstrapDialog>
{/* modal code end here   */}
        </>
    )
}
export default Contactnew;
