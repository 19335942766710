import { configureStore } from '@reduxjs/toolkit'
import UserReducer from './features/userdataSlice';
import PageContentReducer from './features/pageContentSlice';
import { combineReducers } from 'redux';

import storage from 'redux-persist/lib/storage'
import {
  persistStore,
  persistReducer,
} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  userdata: UserReducer,
  pageContents: PageContentReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export const persistor = persistStore(store)
