import { v4 as uuidv4 } from "uuid";
import secureLocalStorage from "react-secure-storage";

const getPaginatedItems = (masterArray, pageNumber, limit) => {
  const startIndex = (pageNumber - 1) * limit;
  const endIndex = startIndex + limit;
  return masterArray.slice(startIndex, endIndex);
};

const updateNullOfObjectValues = (obj) => {
  return Object.entries(obj).reduce((acc, [key, _]) => {
    acc[key] = null;
    return acc;
  }, {});
};

const isPhoneNumber = (phoneNumber) => {
  const regex = /^[0-9]+$/;
  return regex.test(phoneNumber);
};

const isEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};

const isComingSoon = (pages, name) => {
  return Array.isArray(pages)
    ? pages.some((item) => item.name === name && item.status == "active")
    : false;
};

const generateBrowserId = () => {
  let browserId = secureLocalStorage.getItem("browserToken");
  if (!browserId) {
    browserId = uuidv4();
    secureLocalStorage.setItem("browserToken", browserId);
  }
  return browserId;
};

const generateUniqueToken = () => {
  const timestamp = Date.now().toString(36);
  const randomChars = Math.random().toString(36).substr(2, 8);
  const browserId = generateBrowserId();
  const token = timestamp + randomChars + browserId;
  return token;
};

const objectToQueryString = (obj) => {
    return obj ? '?' + Object.entries(obj).map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&') : "";
}

const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
}

export {
  getPaginatedItems,
  updateNullOfObjectValues,
  isPhoneNumber,
  isEmail,
  isComingSoon,
  generateBrowserId,
  generateUniqueToken,
  objectToQueryString,
  generateRandomString
};
