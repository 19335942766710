import { createSlice } from "@reduxjs/toolkit";
import axios from "../actions/axios";
import secureLocalStorage from "react-secure-storage";
import { v4 as uuidv4 } from "uuid";
import {generateBrowserId} from "../utils/helper";

const initialState = {
  value: {},
  token: null,
  formToken: null,
  ip_address: null
};

export const userSlice = createSlice({
  name: "userdata",
  initialState,
  reducers: {
    holdUser: (state, action) => {
      state.value = action.payload;
    },
    loginSuccess: (state, action) => {
      state.value = action.payload.user;
      state.token = action.payload.token;
      axios.defaults.headers[
        "Authorization"
      ] = `Bearer ${action.payload.token}`;
    },
    logout: (state) => {
      state.value = {};
      state.token = null;
      axios.defaults.headers["Authorization"] = "";
      secureLocalStorage.removeItem("token");
    },
    setFormToken: (state, action) => {
      state.formToken = action.payload;
    },
    setIpAddress: (state, action) => {
        state.ip_address = action.payload;
    }
  },
});

export const { holdUser, loginSuccess, logout, setFormToken, setIpAddress } = userSlice.actions;

export default userSlice.reducer;
